import { ChangeRequestsStateEnumType } from '__generated__/types';
import { Modal, PageLoader } from 'components/UI';
import { dateFormat, NA } from 'components/utils/formatters';
import { pick } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useStore } from 'stores/RootStore';

import { ChangesTable } from './ChangesTable';
import { useChangeRequestQuery } from './graphql/queries/__generated__/changeRequest';
import { InvoicesTable } from './InvoicesTable';
import s from './PartnerChangeRequestModal.module.less';
import { PartnerChangeRequestModalFooter } from './PartnerChangeRequestModalFooter';
import { Change, ChangesObject } from './types';

const getChangesArray = (changesObject: ChangesObject): Change[] =>
  Object.entries(changesObject)
    .filter(([_, value]) => Boolean(value))
    .reduce<Change[]>(
      (cur, [fieldName, changeRequestData]) => [
        ...cur,
        { fieldName, currentValue: changeRequestData?.old || NA, newValue: changeRequestData?.new || NA },
      ],
      [],
    );

const PartnerChangeRequestModalComponent: FC = () => {
  const {
    partnerChangeRequestModalStore: { isOpen, changeRequestId, closePartnerChangeRequestModal },
  } = useStore();

  const { data, loading } = useChangeRequestQuery({
    variables: { id: changeRequestId },
    skip: !changeRequestId || !isOpen,
    onError: () => {
      closePartnerChangeRequestModal();
    },
  });
  const { changeRequest } = data || {};
  const { billingAddress: billingAddressChanges } = changeRequest?.data || {};

  return (
    <>
      <PageLoader title="Fetching..." isVisible={loading} />
      <Modal
        open={isOpen && Boolean(changeRequest)}
        width={956}
        className={s.modal}
        onCancel={() => closePartnerChangeRequestModal()}
        title={
          <div className={s.header}>
            <span>Change request</span>
            {changeRequest ? (
              <span className={s.sentByInfo}>
                {changeRequest.state === ChangeRequestsStateEnumType.Approved
                  ? `Approved by ${changeRequest.processedBy?.name} on ${dateFormat(changeRequest.processedAt)}`
                  : `Sent by ${changeRequest.initiatedBy.name} on ${dateFormat(changeRequest.createdAt)}`}
              </span>
            ) : null}
          </div>
        }
        footer={<PartnerChangeRequestModalFooter changeRequest={changeRequest} />}
      >
        <ChangesTable
          title="Partner"
          changes={getChangesArray(
            pick(
              changeRequest?.data,
              'legalName',
              'vatId',
              'datevKonto',
              'notVatPayer',
              'acceptSelfBilling',
            ) as ChangesObject,
          )}
        />
        <ChangesTable
          title="Bank account"
          changes={getChangesArray(
            pick(
              changeRequest?.data,
              'bankAccountHolderName',
              'bankAccount',
              'bankCode',
              'bankName',
              'bankCountry',
              'payoutTransferType',
            ) as ChangesObject,
          )}
        />
        <ChangesTable
          title="Billing address"
          changes={getChangesArray(pick(billingAddressChanges, 'country', 'city', 'zip', 'address') as ChangesObject)}
        />
        {changeRequest?.attachedFiles.length ? (
          <div>
            <div className={s.changesBlockTitle}>Attached files</div>
            {changeRequest.attachedFiles.map(({ name }) => name).join(', ')}
          </div>
        ) : null}
        <div className={s.invoicesBlock}>
          <div>
            <div className={s.changesBlockTitle}>Invoices uploaded, but not checked</div>
            <div>
              <InvoicesTable invoices={changeRequest?.notCheckedInvoices || []} />
            </div>
          </div>
          <div>
            <div className={s.changesBlockTitle}>Invoices checked, not paid yet</div>
            <div>
              <InvoicesTable invoices={changeRequest?.notPaidInvoices || []} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export const PartnerChangeRequestModal = observer(PartnerChangeRequestModalComponent);

import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WorkspacePresetsAssignDefaultPresetMutationVariables = Types.Exact<{
  workspaceName: Types.Scalars['String']['input'];
  presetId: Types.Scalars['ID']['input'];
}>;


export type WorkspacePresetsAssignDefaultPresetMutation = { __typename?: 'Mutation', workspacePresetsAssignDefaultPreset?: { __typename?: 'WorkspacePresetsAssignDefaultPresetPayload', success: boolean } | null };


export const WorkspacePresetsAssignDefaultPresetDocument = gql`
    mutation workspacePresetsAssignDefaultPreset($workspaceName: String!, $presetId: ID!) {
  workspacePresetsAssignDefaultPreset(
    input: {workspaceName: $workspaceName, presetId: $presetId}
  ) {
    success
  }
}
    `;
export type WorkspacePresetsAssignDefaultPresetMutationFn = Apollo.MutationFunction<WorkspacePresetsAssignDefaultPresetMutation, WorkspacePresetsAssignDefaultPresetMutationVariables>;

/**
 * __useWorkspacePresetsAssignDefaultPresetMutation__
 *
 * To run a mutation, you first call `useWorkspacePresetsAssignDefaultPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkspacePresetsAssignDefaultPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workspacePresetsAssignDefaultPresetMutation, { data, loading, error }] = useWorkspacePresetsAssignDefaultPresetMutation({
 *   variables: {
 *      workspaceName: // value for 'workspaceName'
 *      presetId: // value for 'presetId'
 *   },
 * });
 */
export function useWorkspacePresetsAssignDefaultPresetMutation(baseOptions?: Apollo.MutationHookOptions<WorkspacePresetsAssignDefaultPresetMutation, WorkspacePresetsAssignDefaultPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WorkspacePresetsAssignDefaultPresetMutation, WorkspacePresetsAssignDefaultPresetMutationVariables>(WorkspacePresetsAssignDefaultPresetDocument, options);
      }
export type WorkspacePresetsAssignDefaultPresetMutationHookResult = ReturnType<typeof useWorkspacePresetsAssignDefaultPresetMutation>;
export type WorkspacePresetsAssignDefaultPresetMutationResult = Apollo.MutationResult<WorkspacePresetsAssignDefaultPresetMutation>;
export type WorkspacePresetsAssignDefaultPresetMutationOptions = Apollo.BaseMutationOptions<WorkspacePresetsAssignDefaultPresetMutation, WorkspacePresetsAssignDefaultPresetMutationVariables>;
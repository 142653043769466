import { ChangeRequestsStateEnumType } from '__generated__/types';
import { Button, Popconfirm, Tooltip } from 'antd';
import TextArea, { TextAreaRef } from 'antd/lib/input/TextArea';
import { observer } from 'mobx-react-lite';
import React, { FC, useRef, useState } from 'react';
import { useStore } from 'stores/RootStore';

import { useChangeRequestApproveMutation } from './graphql/mutations/__generated__/changeRequestApprove';
import { useChangeRequestDeclineMutation } from './graphql/mutations/__generated__/changeRequestDecline';
import { ChangeRequestQuery } from './graphql/queries/__generated__/changeRequest';
import s from './PartnerChangeRequestModal.module.less';

const PartnerChangeRequestModalFooterComponent: FC<{
  changeRequest: ChangeRequestQuery['changeRequest'] | undefined;
}> = ({ changeRequest }) => {
  const {
    partnerChangeRequestModalStore: { closePartnerChangeRequestModal },
    userStore: currentUser,
  } = useStore();
  const declineReasonTextAreaRef = useRef<TextAreaRef | null>(null);
  const [popConfirmOpen, setPopConfirmOpen] = useState(false);
  const [declineChangeRequest, { loading: declineLoading }] = useChangeRequestDeclineMutation({
    refetchQueries: ['provider'],
  });
  const [approveChangeRequest, { loading: approveLoading }] = useChangeRequestApproveMutation({
    refetchQueries: ['provider'],
  });

  if (!changeRequest || changeRequest.state === ChangeRequestsStateEnumType.Approved) {
    return null;
  }

  const closePopConfirm = () => {
    setPopConfirmOpen(false);

    if (declineReasonTextAreaRef.current?.resizableTextArea?.textArea) {
      declineReasonTextAreaRef.current.resizableTextArea.textArea.value = '';
    }
  };

  const handleDeclineClick = () => {
    void declineChangeRequest({
      variables: {
        input: {
          id: changeRequest.id,
          reason: declineReasonTextAreaRef.current?.resizableTextArea?.textArea.value || '',
        },
      },
    }).then(() => {
      closePopConfirm();
      closePartnerChangeRequestModal();
    });
  };

  const handleApproveClick = () => {
    void approveChangeRequest({ variables: { input: { id: changeRequest.id } } }).then(() => {
      closePopConfirm();
      closePartnerChangeRequestModal();
    });
  };

  const ableToApprove = String(changeRequest.initiatedBy.id) !== String(currentUser.id);

  return (
    <div className={s.footer}>
      <Popconfirm
        title={
          <div>
            <p>Add the reason for the declination:</p>
            <TextArea autoSize ref={declineReasonTextAreaRef} placeholder="Reason" />
          </div>
        }
        icon={null}
        open={popConfirmOpen}
        onConfirm={handleDeclineClick}
        onCancel={closePopConfirm}
        okText="Submit"
        cancelText="Cancel"
        okButtonProps={{ loading: declineLoading }}
      >
        <Button
          danger
          onClick={() => {
            setPopConfirmOpen((open) => !open);
          }}
        >
          Decline
        </Button>
      </Popconfirm>
      <div>
        <Button onClick={closePartnerChangeRequestModal}>Close</Button>
        <Tooltip title={ableToApprove ? '' : 'You cannot approve own change requests'}>
          <Button type="primary" disabled={!ableToApprove} loading={approveLoading} onClick={handleApproveClick}>
            Approve
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export const PartnerChangeRequestModalFooter = observer(PartnerChangeRequestModalFooterComponent);

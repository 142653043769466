import { useMonitoringClient } from '@xometry/ui';
import { PartnerChangeRequestModal } from 'components/PartnerChangeRequestModal/PartnerChangeRequestModal';
import PrivateRoute from 'components/PrivateRoute';
import { SideOverlayContainer } from 'components/SideOverlayContainer';
import SmartRouter from 'components/SmartRouter';
import { TemplatesModal } from 'components/TemplatesModal';
import { Container } from 'components/UI/shared/styled';
import { DATEV_ACCOUNTANT_SYSTEM_ENABLED, HAS_ACCOUNTING_NAME } from 'config/regions/features';
import { AppContextProvider } from 'contexts/AppContextProvider';
import CrmController from 'controllers/Crm';
import LookersController from 'controllers/Lookers';
import PartnerController from 'controllers/PartnerController';
import { observer } from 'mobx-react-lite';
import {
  DealPage,
  FilterGroupPage,
  MessageCenterPage,
  NotFoundPage,
  PrinterPage,
  SignInPage,
  SsoCallbackPage,
  StartPage,
  TemplatesPage,
  TemplatesPlaceholdersPage,
} from 'pages';
import React, { FC, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { useStore } from 'stores/RootStore';
import { lazyLoadPage } from 'utils/lazyLoad';

import AccountingController from './Accounting';
import BackofficeController from './BackofficeController';
import BankPaymentsController from './BankPayments';
import { preloadRoutes } from './BaseController.utils';
import BillingAccountsController from './BillingAccountsController';
import { DashboardsController } from './DashboardsController';
import DatevController from './Datev';
import LogisticInvoicesController from './LogisticInvoicesController';
import { MasterShippingsController } from './MasterShippingsController';
import RolesController from './RolesController';
import StoragesController from './StoragesController';
import { TeamsController } from './TeamsController';
import UsersController from './UsersController';
import WorkspacesController from './WorkspacesController';

const Header = lazyLoadPage(() =>
  import(/* webpackChunkName: "Header" */ 'components/Header/index').then((r) => r.Header),
);

const BaseControllerComponent: FC = () => {
  const store = useStore();
  const history = useHistory();
  const monitoring = useMonitoringClient();
  const userId = String(store?.userStore?.id || 'Unauthorized');

  /**
   * Preload all routes once user spent a lot of time on the tab.
   * This way we have:
   * 1) All routes preloaded, so user won't spent time waiting for the next page to be loaded
   * 2) This kinda saves us/user when new version is released and old chunks aren't accessible anymore.
   */
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const id = setTimeout(() => {
        void preloadRoutes();
      }, 20000);

      return () => {
        clearTimeout(id);
      };
    }
  }, []);

  useEffect(() => {
    if (!monitoring) {
      return;
    }

    monitoring.setClientId(userId);
  }, [monitoring, userId]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    store.history = history;
  }, [store, history]);

  return (
    <AppContextProvider>
      <Switch>
        <Route path={routes.notFound()} component={NotFoundPage} exact />

        <Route path={routes.signInPath()} component={SignInPage} exact />

        <Route path={routes.ssoCallbackPath()} component={SsoCallbackPage} exact />

        <PrivateRoute
          path={routes.messageCenterPath()}
          exact
          component={() => (
            <>
              <MessageCenterPage />
              <TemplatesModal />
            </>
          )}
        />

        <PrivateRoute
          path={routes.rootPath()}
          component={() => (
            <>
              {/* General page header */}
              <Header />

              <Container>
                <SideOverlayContainer />

                <Route path={routes.rootPath()} component={StartPage} exact />

                <DashboardsController />

                <LookersController />

                <CrmController />

                <PartnerController />

                {HAS_ACCOUNTING_NAME && <AccountingController />}

                {DATEV_ACCOUNTANT_SYSTEM_ENABLED && <DatevController />}

                <BankPaymentsController />

                <WorkspacesController />

                <Route path={routes.printerPath()} component={PrinterPage} />

                <Route path={routes.smartRouterPath()} component={SmartRouter} />

                <BillingAccountsController />

                <StoragesController />

                <BackofficeController />

                <UsersController />

                <RolesController />

                <LogisticInvoicesController />

                <MasterShippingsController />

                <Switch>
                  <Route path={routes.messageFiltersPath()} component={FilterGroupPage} exact />
                  <Route path={routes.messageFiltersGroupPath(':id')} component={FilterGroupPage} exact />
                </Switch>

                <Route path={routes.templatesPlaceholdersPath()} component={TemplatesPlaceholdersPage} />

                <Route
                  path={[routes.dealsPath(), routes.pDealsPath()]}
                  component={() => (
                    <Route
                      path={[`${routes.dealsPath()}/:dealId`, `${routes.pDealsPath()}/:dealId`]}
                      component={DealPage}
                    />
                  )}
                />

                <Route path={routes.templates()} component={TemplatesPage} />

                <TeamsController />

                <TemplatesModal />

                <PartnerChangeRequestModal />
              </Container>
            </>
          )}
        />

        <Redirect to={routes.notFound()} />
      </Switch>
    </AppContextProvider>
  );
};

export const BaseController = observer(BaseControllerComponent);

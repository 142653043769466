import { EMessengerChatSourceType } from '@xometry/ui';
import { EFeatureFlags } from 'collections/featureFlags';
import { useMemo } from 'react';
import { useFeatureFlagsQuery } from 'utils/graphql/queries/__generated__/featureFlags';

const useFeatureFlags = () => {
  const { data } = useFeatureFlagsQuery();

  const flags = useMemo(() => {
    if (!data) {
      return {};
    }

    return data?.featureFlags.reduce((acc: Record<string, boolean>, flag) => {
      if (flag.enabled) {
        acc[flag.name] = flag.enabled;
      }

      return acc;
    }, {});
  }, [data]);

  return flags;
};

export const useFeatureEnabled = (featureName: string) => {
  const flags = useFeatureFlags();

  return Boolean(flags[featureName]);
};

export const useNewTemplatesModalEnabled = (sourceType: EMessengerChatSourceType) => {
  const flags = useFeatureFlags();

  if (process.env.NODE_ENV === 'development') {
    return true;
  }

  switch (sourceType) {
    case EMessengerChatSourceType.CUSTOMER:
      return Boolean(flags[EFeatureFlags.ERP_TEMPLATES_MODAL_EMAIL_CUSTOMER]);
    case EMessengerChatSourceType.ENQUIRY:
      return Boolean(flags[EFeatureFlags.ERP_TEMPLATES_MODAL_EMAIL_ENQUIRY]);
    case EMessengerChatSourceType.ORDER_CONFIRMATION:
      return Boolean(flags[EFeatureFlags.ERP_TEMPLATES_MODAL_EMAIL_OC]);
    case EMessengerChatSourceType.OUTCOMING_INVOICE:
      return Boolean(flags[EFeatureFlags.ERP_TEMPLATES_MODAL_EMAIL_OI]);
    case EMessengerChatSourceType.INCOMING_INVOICE:
      return Boolean(flags[EFeatureFlags.ERP_TEMPLATES_MODAL_EMAIL_II]);
    case EMessengerChatSourceType.QUALITY_CONTROL:
      return Boolean(flags[EFeatureFlags.ERP_TEMPLATES_MODAL_EMAIL_QUALITY_CONTROL]);
    case EMessengerChatSourceType.CLAIM:
      return Boolean(flags[EFeatureFlags.ERP_TEMPLATES_MODAL_EMAIL_CLAIM]);
    case EMessengerChatSourceType.PAYMENT:
      return Boolean(flags[EFeatureFlags.ERP_TEMPLATES_MODAL_EMAIL_PAYMENT]);

    default: {
      return false;
    }
  }
};

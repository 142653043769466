import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import s from './Link.module.less';

export type LinkProps = React.ComponentProps<typeof RouterLink> & {
  semibold?: boolean;
  external?: boolean;
  targetBlank?: boolean;
  disabled?: boolean;
  fontSize?: number;
};

export const Link: React.FC<LinkProps> = (props) => {
  const { semibold, external, targetBlank, disabled, fontSize, ...rest } = props;

  const className = clsx(s.root, props.className, {
    [s.semibold]: semibold,
    [s.disabled]: disabled,
  });

  const style = fontSize ? { fontSize: `${fontSize}px`, ...props.style } : props.style;

  if (external) {
    return (
      <a
        target={targetBlank ? '_blank' : undefined}
        rel={targetBlank ? 'noopener noreferrer' : undefined}
        {...rest}
        href={rest.to as string}
        className={className}
        style={style}
      >
        {props.children}
      </a>
    );
  }

  return <RouterLink {...rest} className={className} style={style} />;
};

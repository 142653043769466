import {
  DashboardDraftingEngineerPage,
  DashboardFinancePage,
  DashboardRfqManagersPage,
  DashboardSalesPersonPage,
  ForceDashboardProductionManagerPage,
} from 'pages';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes } from 'routes';

export const DashboardsController = () => {
  return (
    <Switch>
      <Redirect from={routes.dashboardOldProductionManagerPath} to={routes.dashboardProductionManagerPath} />
      <Route path={routes.dashboardProductionManagerPath} component={ForceDashboardProductionManagerPage} />
      <Route path={routes.dashboardDraftingEngineerPath} component={DashboardDraftingEngineerPage} />
      <Route path={routes.dashboardFinancePath} component={DashboardFinancePage} />
      <Route path={routes.dashboardSalesPersonPath} component={DashboardSalesPersonPage} />
      <Route path={routes.dashboardRfqManagersPath} component={DashboardRfqManagersPage} />
    </Switch>
  );
};

import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { MoneyFragmentFragmentDoc } from '../../../../utils/graphql/fragments/__generated__/moneyFragment';
export type ChangeRequestInvoiceFragment = { __typename?: 'IncomingInvoice', id: string, dealId: string, issuedAt?: string | null, displayName: string, paymentDueDate?: string | null, allocatedAmountMoney: { __typename?: 'Money', amount: string, amountCents: string, currencyCode: Types.CurrencyEnum } };

export const ChangeRequestInvoiceFragmentDoc = gql`
    fragment changeRequestInvoice on IncomingInvoice {
  id
  dealId
  issuedAt
  displayName
  paymentDueDate
  allocatedAmountMoney {
    ...moneyFragment
  }
}
    ${MoneyFragmentFragmentDoc}`;
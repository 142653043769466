import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { EnquiryMaterialFragmentFragmentDoc } from '../../fragments/__generated__/enquiryMaterialFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EnquiryMaterialsQueryVariables = Types.Exact<{
  materialTypeId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type EnquiryMaterialsQuery = { __typename?: 'Query', enquiryMaterials: Array<{ __typename?: 'Material', id: string, name: string, options?: object | null, tolerancesNew: Array<{ __typename?: 'Tolerance', default: boolean, i18nKey?: string | null, id: number, name: string, quantityEnabled: boolean, value: string }> }> };


export const EnquiryMaterialsDocument = gql`
    query enquiryMaterials($materialTypeId: ID) {
  enquiryMaterials(materialTypeId: $materialTypeId) {
    ...enquiryMaterialFragment
  }
}
    ${EnquiryMaterialFragmentFragmentDoc}`;

/**
 * __useEnquiryMaterialsQuery__
 *
 * To run a query within a React component, call `useEnquiryMaterialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnquiryMaterialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnquiryMaterialsQuery({
 *   variables: {
 *      materialTypeId: // value for 'materialTypeId'
 *   },
 * });
 */
export function useEnquiryMaterialsQuery(baseOptions?: Apollo.QueryHookOptions<EnquiryMaterialsQuery, EnquiryMaterialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnquiryMaterialsQuery, EnquiryMaterialsQueryVariables>(EnquiryMaterialsDocument, options);
      }
export function useEnquiryMaterialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnquiryMaterialsQuery, EnquiryMaterialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnquiryMaterialsQuery, EnquiryMaterialsQueryVariables>(EnquiryMaterialsDocument, options);
        }
export function useEnquiryMaterialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EnquiryMaterialsQuery, EnquiryMaterialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EnquiryMaterialsQuery, EnquiryMaterialsQueryVariables>(EnquiryMaterialsDocument, options);
        }
export type EnquiryMaterialsQueryHookResult = ReturnType<typeof useEnquiryMaterialsQuery>;
export type EnquiryMaterialsLazyQueryHookResult = ReturnType<typeof useEnquiryMaterialsLazyQuery>;
export type EnquiryMaterialsSuspenseQueryHookResult = ReturnType<typeof useEnquiryMaterialsSuspenseQuery>;
export type EnquiryMaterialsQueryResult = Apollo.QueryResult<EnquiryMaterialsQuery, EnquiryMaterialsQueryVariables>;
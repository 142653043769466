import { RETURN_INVOICE_INSTEAD_OF_CREDIT_NOTE } from 'config/regions/features';
import { ObjectType } from 'interfaces';
import { ObjectTypes } from 'stores/Deal/DealSidebarStore';

interface Args {
  filterCheck: (item: ObjectTypes) => boolean;
  items: ObjectTypes[];
  mapKeyIterator: (item: ObjectTypes) => (key: string) => boolean;
}

export const structureItems = ({ filterCheck, items, mapKeyIterator }: Args) => {
  const resultMap: { [key: string]: ObjectTypes } = {};

  items.forEach((item) => {
    resultMap[item.name] = { ...item, children: item.children ? [...item.children] : [] };
  });

  items.forEach((item) => {
    if (filterCheck(item)) {
      const itemKeys = Object.keys(resultMap);
      const mapKey = itemKeys.find(mapKeyIterator(item));
      const parentItem = mapKey && resultMap[mapKey] ? resultMap[mapKey] : null;

      if (parentItem) {
        parentItem.children?.push(item);
      }
    }
  });

  return Object.values(resultMap).filter((item) => !filterCheck(item));
};

export const getStructuredItems = (items: ObjectTypes[]) => {
  /** Important to save order (to save hierarchy from last level to
   *  top-level) */
  const structureSettings = {
    resultPRZtoPZ: {
      filterCheck: (item: ObjectTypes) =>
        Boolean(
          item.objectType === ObjectType.PZ && item.isRefund && item.sourceId && item.sourceType === ObjectType.PZ,
        ),
      mapKeyIterator: (item: ObjectTypes) => (key: string) =>
        key.includes(String(item.sourceId)) && (key.includes('PZ-') || key.includes('PRZ-')),
    },
    resultCNtoOI: {
      filterCheck: (item: ObjectTypes) =>
        Boolean(item.objectType === ObjectType.OI && item.creditNote && item.refInvoice?.id),
      mapKeyIterator: (item: ObjectTypes) => (key: string) => {
        const neededObj = items.find((i) => i.name === item.refInvoice?.number && i.objectType === ObjectType.OI);

        return RETURN_INVOICE_INSTEAD_OF_CREDIT_NOTE
          ? key === item.refInvoice?.number && neededObj?.objectType === ObjectType.OI
          : key.includes(String(item.refInvoice?.number)) && (key.includes('R-') || key.includes('OI-'));
      },
    },
    resultRefundToPayment: {
      filterCheck: (item: ObjectTypes) =>
        Boolean(
          item.objectType === ObjectType.Payment &&
            item.isRefund &&
            item.sourceId &&
            item.sourceType === ObjectType.Payment,
        ),
      mapKeyIterator: (item: ObjectTypes) => (key: string) =>
        key.includes(String(item.sourceId)) && key.includes('PI-'),
    },
    resultPZToII: {
      filterCheck: (item: ObjectTypes) =>
        Boolean(
          item.objectType === ObjectType.PZ && !item.isRefund && item.sourceId && item.sourceType === ObjectType.II,
        ),
      mapKeyIterator: (item: ObjectTypes) => (key: string) => {
        const foundII = items.find((i) => item.sourceId === String(i.id));

        return (
          (key.includes(String(item.sourceId)) || key === foundII?.name) &&
          (key.includes('LR-') || key.includes('LG-') || key.includes('XLR-') || key.includes('XLG-'))
        );
      },
    },
    resultPRZToCN: {
      filterCheck: (item: ObjectTypes) =>
        Boolean(
          item.objectType === ObjectType.PZ && item.isRefund && item.sourceId && item.sourceType === ObjectType.II,
        ),
      mapKeyIterator: (item: ObjectTypes) => (key: string) => {
        const foundII = items.find((i) => item.sourceId === String(i.id));

        return (
          (key.includes(String(item.sourceId)) || key === foundII?.name) &&
          (key.includes('LG-') || key.includes('XLG-'))
        );
      },
    },
    resultIIToPO: {
      filterCheck: (item: ObjectTypes) => Boolean(item.objectType === ObjectType.II && item.providerOrderId),
      mapKeyIterator: (item: ObjectTypes) => (key: string) =>
        key.includes(String(item.providerOrderId)) && key.includes('PO-'),
    },
    resultPZToPO: {
      filterCheck: (item: ObjectTypes) =>
        Boolean(item.objectType === ObjectType.PZ && item.sourceId && item.sourceType === ObjectType.PO),
      mapKeyIterator: (item: ObjectTypes) => (key: string) =>
        key.includes(String(item.sourceId)) && key.includes('PO-'),
    },
    resultSamplePOtoPO: {
      filterCheck: (item: ObjectTypes) => Boolean(item.objectType === ObjectType.PO && item.samplesMainProviderOrderId),
      mapKeyIterator: (item: ObjectTypes) => (key: string) =>
        key.includes(String(item.samplesMainProviderOrderId)) && key.includes('PO-'),
    },
  };

  // Due to performance issues, we return items as is if their length is greater than 1000
  // For business it's not important whether they are structured or not, if there are too many items,
  // because this is always an exception for the business
  if (items.length > 1000) {
    return items;
  }

  let structuredResult: ObjectTypes[] = items;

  Object.keys(structureSettings).forEach((key) => {
    const { filterCheck, mapKeyIterator } = structureSettings[key as keyof typeof structureSettings];
    structuredResult = structureItems({ filterCheck, items: structuredResult, mapKeyIterator });
  });

  return structuredResult;
};

export const sortWithChildren = (items: ObjectTypes[]) => {
  return items.sort((a, b) => {
    const aHasChildren = a.children && a.children.length > 0;
    const bHasChildren = b.children && b.children.length > 0;

    if (!aHasChildren && bHasChildren) {
      return -1;
    }

    if (aHasChildren && !bHasChildren) {
      return 1;
    }

    return 0;
  });
};

import { TemplateLocaleEnum } from '__generated__/types';
import { EAttachmentStatus } from '@xometry/ui';
import { AttachmentFragmentFragment } from 'utils/graphql/fragments/__generated__/attachmentsFragment';

export enum TemplateModalFormFieldEnum {
  Attachments = 'attachments',
  Category = 'category',
  Content = 'content',
  TemplateConstantName = 'templateConstantName',
  Locale = 'locale',
  Mentions = 'mentions',
  To = 'to',
  Cc = 'cc',
  Bcc = 'bcc',
}

export type StateAttachment = Omit<AttachmentFragmentFragment, '__typename'> & {
  value?: string;
  status: EAttachmentStatus;
};

export interface TemplateModalFormValues {
  [TemplateModalFormFieldEnum.Attachments]: StateAttachment[];
  [TemplateModalFormFieldEnum.Category]: string;
  [TemplateModalFormFieldEnum.Content]: string;
  [TemplateModalFormFieldEnum.TemplateConstantName]: string;
  [TemplateModalFormFieldEnum.Locale]: TemplateLocaleEnum;
  [TemplateModalFormFieldEnum.Mentions]: string[];
  [TemplateModalFormFieldEnum.To]: string[];
  [TemplateModalFormFieldEnum.Cc]: string[];
  [TemplateModalFormFieldEnum.Bcc]: string[];
}

import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { CalculationsFragmentFragmentDoc } from './calculationsFragment';
export type EnquiryFragmentFragment = { __typename?: 'Enquiry', id: number, dealId: string, name?: string | null, state: string, certificates?: object | null, billingAccount?: object | null, currency: string, calculations: { __typename?: 'Calculations', loyalty: { __typename?: 'Loyalty', xPointsCurrencyCoef: number } } };

export const EnquiryFragmentFragmentDoc = gql`
    fragment enquiryFragment on Enquiry {
  id
  dealId
  name
  state
  certificates
  billingAccount
  currency
  calculations {
    ...calculationsFragment
  }
}
    ${CalculationsFragmentFragmentDoc}`;
import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { ToleranceFragmentFragmentDoc } from './toleranceFragment';
export type EnquiryMaterialFragmentFragment = { __typename?: 'Material', id: string, name: string, options?: object | null, tolerancesNew: Array<{ __typename?: 'Tolerance', default: boolean, i18nKey?: string | null, id: number, name: string, quantityEnabled: boolean, value: string }> };

export const EnquiryMaterialFragmentFragmentDoc = gql`
    fragment enquiryMaterialFragment on Material {
  id
  name
  options
  tolerancesNew {
    ...toleranceFragment
  }
}
    ${ToleranceFragmentFragmentDoc}`;
import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
export type ErpPermissionFragmentFragment = { __typename?: 'ErpPermission', id: number, group: string, action: string, name: string, label?: string | null };

export const ErpPermissionFragmentFragmentDoc = gql`
    fragment erpPermissionFragment on ErpPermission {
  id
  group
  action
  name
  label
}
    `;
import { action, computed, observable } from 'mobx';

class PartnerChangeRequestModalStore {
  @observable changeRequestId: string = '';

  @computed get isOpen() {
    return Boolean(this.changeRequestId);
  }

  @action openPartnerChangeRequestModal = (changeRequestId: string) => {
    this.changeRequestId = changeRequestId;
  };

  @action closePartnerChangeRequestModal = () => {
    this.changeRequestId = '';
  };
}

export default PartnerChangeRequestModalStore;

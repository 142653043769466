import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
export type ToleranceFragmentFragment = { __typename?: 'Tolerance', default: boolean, i18nKey?: string | null, id: number, name: string, quantityEnabled: boolean, value: string };

export const ToleranceFragmentFragmentDoc = gql`
    fragment toleranceFragment on Tolerance {
  default
  i18nKey
  id
  name
  quantityEnabled
  value
}
    `;
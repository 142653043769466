import { DownloadOutlined, WarningFilled } from '@ant-design/icons';
import { Row, Tooltip } from 'antd';
import { LinkButton } from 'components/UI/Link/styled';
import { EllipsisedText } from 'components/UI/Upload/styled';
import { BucketItem, IAttachedFile } from 'interfaces';
import { compact, intersection, isEmpty, isNil } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React, { MouseEvent } from 'react';
import { routesApi } from 'routes/api';
import { useStore } from 'stores/RootStore';
import useDownload from 'utils/hooks/useDownload';

import NoPreview from './Modal/NoPreview';
import ScsNoPreview from './Modal/ScsNoPreview';
import { DivImg, NotCleaned, OverlaySpinner, PreviewItemDiv } from './styled';

interface NotCleanedBadgeProps {
  imgSize?: string;
}

const NotCleanedBadge: React.FC<NotCleanedBadgeProps> = ({ imgSize }) => (
  <NotCleaned imgSize={imgSize}>
    <WarningFilled style={{ marginRight: '3px' }} />
    {imgSize !== '60px' && 'No cleaned'}
  </NotCleaned>
);

interface Props {
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
  preview: IAttachedFile | BucketItem;
  imgSize?: string;
  wNames?: boolean;
  showProgress?: boolean;
  className?: string;
  ignoreCleaned?: boolean;
}

const PreviewItem = observer<Props>(({ preview, onClick, imgSize, wNames, showProgress, className, ignoreCleaned }) => {
  const { previewsStore } = useStore();
  const download = useDownload();

  let isCleaned = preview.context !== 'default';
  isCleaned = isCleaned || !isNil(preview.cleanedId);
  isCleaned = isCleaned || !!preview.isCleaned;

  const isSelected = !isEmpty(
    intersection(
      compact([preview.id, preview.cleanedId, preview.parentId]),
      compact([
        previewsStore.currentPreview?.id,
        previewsStore.currentPreview?.cleanedId,
        previewsStore.currentPreview?.parentId,
      ]),
    ),
  );

  const handleDownload = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    void download(routesApi.attachedFileDownloadPath(preview.id), preview.name);
  };

  const isSCS = !!String(preview.url).match(/.+\.(scs)[?]?/);

  return (
    <PreviewItemDiv
      onClick={onClick}
      isSelected={isSelected}
      isCleaned={isCleaned}
      imgSize={imgSize}
      className={className}
      ignoreCleaned={ignoreCleaned}
    >
      {!isSCS && preview.url && (
        <>
          {!isCleaned && !ignoreCleaned && <NotCleanedBadge imgSize={imgSize} />}
          <Row style={{ width: '100%' }} justify="center">
            <img alt="Preview" src={preview.url} />
            {wNames && (
              <LinkButton
                icon={<DownloadOutlined />}
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  textAlign: 'left',
                  lineHeight: 'initial',
                  height: 'auto',
                  width: '100%',
                }}
                type="link"
                onClick={handleDownload}
              >
                <Tooltip title={preview.name}>
                  <EllipsisedText className="ellipsis-text">{preview.name}</EllipsisedText>
                </Tooltip>
              </LinkButton>
            )}
          </Row>
        </>
      )}
      {!isSCS && !preview.url && (
        <DivImg imgSize={imgSize}>
          <NoPreview />
        </DivImg>
      )}
      {isSCS && (
        <DivImg imgSize={imgSize}>
          <ScsNoPreview />
        </DivImg>
      )}
      {showProgress ? <OverlaySpinner /> : null}
    </PreviewItemDiv>
  );
});

export default PreviewItem;

import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
export type AttachmentFragmentFragment = { __typename?: 'Attachment', id: string, uuidId: string, name: string, previewUrl?: string | null, previewLargeUrl?: string | null };

export const AttachmentFragmentFragmentDoc = gql`
    fragment attachmentFragment on Attachment {
  id
  uuidId
  name
  previewUrl
  previewLargeUrl
}
    `;
import styled from '@emotion/styled';
import { Col } from 'antd';
import { bc } from 'components/UI/variables';

export const ColCenterContent = styled(Col)`
  padding-top: 10px;
  padding-left: 10px;

  background: ${bc.contentBackground};

  .inner-content {
    background-color: white;
    height: 100%;
  }
`;

export const ColLeftSidebar = styled(Col)`
  border-right: 1px solid ${bc.border};
`;

export const ColRightSidebar = styled(Col)`
  background: ${bc.contentBackground};
  padding: 10px 0 10px 9px;

  > div {
    border-left: 1px solid ${bc.border};
    border-right: 1px solid ${bc.border};
    background: white;

    &:first-of-type {
      border-top: 1px solid ${bc.border};
    }
    &:last-of-type {
      border-bottom: 1px solid ${bc.border};
    }
    &:nth-of-type(even) {
      border-top: 1px solid ${bc.border};
    }
    &:nth-of-type(odd) {
      border-top: 1px solid ${bc.border};
    }
  }
`;

import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
export type AttachedFileFragment = { __typename?: 'AttachedFile', id: string, context: string, isCleaned: boolean, isStp?: boolean | null, name: string, parentId?: number | null, uid: string, url?: string | null, largeUrl?: string | null };

export const AttachedFileFragmentDoc = gql`
    fragment attachedFile on AttachedFile {
  id
  context
  isCleaned
  isStp
  name
  parentId
  uid
  url
  largeUrl
}
    `;
import { lazyLoadPage } from 'utils/lazyLoad';

import { ErrorPage as ErrorPageComponent } from './ErrorPage/ErrorPage';

export const NotFoundPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "NotFoundPage" */ './NotFound').then((r) => r.NotFound),
);
export const SignInPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "SignInPage" */ '../components/Signin/index').then((r) => r.SignIn),
);
export const SsoCallbackPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "SsoCallbackPage" */ '../components/SsoCallback/index').then((r) => r.SsoCallback),
);
export const PrinterPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "PrinterPage" */ '../components/Printer').then((r) => r.Printer),
);

export const StartPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "StartPage" */ '../components/StartPage').then((r) => r.StartPage),
);

export const DealPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "DealPage" */ './deal/DealPage').then((r) => r.DealPage),
);

export const EditMasterShippingPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "EditMasterShippingPage" */ 'components/masterShipping/EditMasterShipping').then(
    (r) => r.EditMasterShipping,
  ),
);

export const ForceDashboardProductionManagerPage = lazyLoadPage(() =>
  import(
    /* webpackChunkName: "ForceDashboardProductionManagerPage" */ 'components/dashboards/DashboardProductionManager/ForceDashboardProductionManagerPage'
  ).then((r) => r.ForceDashboardProductionManagerPage),
);
export const DashboardDraftingEngineerPage = lazyLoadPage(() =>
  import(
    /* webpackChunkName: "DashboardDraftingEngineerPage" */ 'components/dashboards/DashboardDraftingEngineer'
  ).then((r) => r.DashboardDraftingEngineer),
);
export const DashboardFinancePage = lazyLoadPage(() =>
  import(/* webpackChunkName: "DashboardFinancePage" */ 'components/dashboards/DashboardFinance').then(
    (r) => r.DashboardFinance,
  ),
);
export const DashboardSalesPersonPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "DashboardSalesPersonPage" */ 'components/dashboards/DashboardSalesPerson').then(
    (r) => r.DashboardSalesPerson,
  ),
);

export const DashboardRfqManagersPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "DashboardRfqManagersPage" */ 'components/dashboards/DashboardRfqManagers').then(
    (r) => r.DashboardRfqManagers,
  ),
);

export const LookerPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "LookerPage" */ 'components/Looker').then((r) => r.Looker),
);

export const PersonNewPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "PersonNewPage" */ 'components/Person/New').then((r) => r.PersonNew),
);
export const PersonPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "PersonPage" */ 'components/Person').then((r) => r.Person),
);

export const OrganizationPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "OrganizationPage" */ 'components/Organization').then((r) => r.Organization),
);
export const OrganizationNewPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "OrganizationNewPage" */ 'components/Organization/New').then((r) => r.OrganizationNew),
);
export const OrganizationBalancePage = lazyLoadPage(() =>
  import(/* webpackChunkName: "OrganizationBalancePage" */ 'components/OrganizationBalance').then(
    (r) => r.OrganizationBalance,
  ),
);

export const SearchPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "SearchPage" */ 'components/SearchPage/SearchPage').then((r) => r.SearchPage),
);

export const PromotionEditPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "PromotionEditPage" */ 'components/Promotion/Edit').then((r) => r.default),
);
export const PromotionNewPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "PromotionNewPage" */ 'components/Promotion/New').then((r) => r.default),
);

export const ProviderNewPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "ProviderNewPage" */ 'components/Partner/Provider/New').then((r) => r.ProviderNew),
);
export const ProviderEditPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "ProviderEditPage" */ 'components/Partner/Provider/Edit').then((r) => r.ProviderEdit),
);
export const ProviderContactsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "ProviderContactsPage" */ 'components/Partner/Provider/Contacts').then(
    (r) => r.ProviderContacts,
  ),
);
export const ProviderAvailabilityCalendarPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "ProviderContactsPage" */ 'components/Partner/Provider/AvailabilityCalendar').then(
    (r) => r.AvailabilityCalendar,
  ),
);
export const ProviderOrdersPage = lazyLoadPage(() =>
  import(
    /* webpackChunkName: "ProviderOrdersPage" */ 'components/Workspaces/General/ProviderOrders/ProviderOrders'
  ).then((r) => r.ProviderOrders),
);
export const ProductionScheduleItemsPage = lazyLoadPage(() =>
  import('components/Workspaces/General/ProductionScheduleItems/ProductionScheduleItems').then(
    (r) => r.ProductionScheduleItems,
  ),
);
export const IncomingInvoicesPage = lazyLoadPage(() =>
  import(
    /* webpackChunkName: "IncomingInvoicesPage" */ 'components/Workspaces/General/IncomingInvoices/IncomingInvoices'
  ).then((r) => r.IncomingInvoices),
);
export const ShippingsListPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "ShippingsListPage" */ 'components/Workspaces/General/Shippings/Shippings').then(
    (r) => r.Shippings,
  ),
);
export const JobOffersListPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "JobOffersListPage" */ 'components/Workspaces/General/JobOffers/JobOffers').then(
    (r) => r.JobOffers,
  ),
);
export const PartnerBalancePage = lazyLoadPage(() =>
  import(/* webpackChunkName: "PartnerBalancePage" */ 'components/Partner/Balance/index').then((r) => r.PartnerBalance),
);
export const AccountingExportOutcomingPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "AccountingExportOutcomingPage" */ 'components/Accounting/Export/Outcoming/List').then(
    (r) => r.AccountingExportOutcoming,
  ),
);
export const AccountingExportBillingAccountsPage = lazyLoadPage(() =>
  import(
    /* webpackChunkName: "AccountingExportBillingAccountsPage" */ 'components/Accounting/Export/BillingAccounts/List'
  ).then((r) => r.AccountingExportBillingAccounts),
);
export const AccountingExportBillingAccountsShowPage = lazyLoadPage(() =>
  import(
    /* webpackChunkName: "AccountingExportBillingAccountsShowPage" */ 'components/Accounting/Export/BillingAccounts/Show'
  ).then((r) => r.AccountingExportBillingAccountsShow),
);
export const AccountingExportProvidersPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "AccountingExportProvidersPage" */ 'components/Accounting/Export/Providers/List').then(
    (r) => r.AccountingExportProviders,
  ),
);
export const AccountingExportProvidersShowPage = lazyLoadPage(() =>
  import(
    /* webpackChunkName: "AccountingExportProvidersShowPage" */ 'components/Accounting/Export/Providers/Show'
  ).then((r) => r.AccountingExportProvidersShow),
);
export const DatevExportBankPaymentsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "DatevExportBankPaymentsPage" */ 'components/Datev/Export/BankPayments/List').then(
    (r) => r.DatevExportBankPayments,
  ),
);
export const ExportPaymentAllocationsListPage = lazyLoadPage(() =>
  import(
    /* webpackChunkName: "ExportPaymentAllocationsListPage" */ 'components/Datev/Export/PaymentAllocations/ExportPaymentAllocationsList'
  ).then((r) => r.ExportPaymentAllocationsList),
);
export const ExportPaymentAllocationsShowPage = lazyLoadPage(() =>
  import(
    /* webpackChunkName: "ExportPaymentAllocationsShowPage" */ 'components/Datev/Export/PaymentAllocations/ExportPaymentAllocationsShow'
  ).then((r) => r.ExportPaymentAllocationsShow),
);
export const DatevExportBankPaymentsShowPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "DatevExportBankPaymentsShowPage" */ 'components/Datev/Export/BankPayments/Show').then(
    (r) => r.DatevExportBankPaymentsShow,
  ),
);
export const DatevExportPaymentsShowPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "DatevExportPaymentsShowPage" */ 'components/Datev/Export/Payments/Show').then(
    (r) => r.DatevExportPaymentsShow,
  ),
);
export const DatevExportPayoutsShowPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "DatevExportPayoutsShowPage" */ 'components/Datev/Export/BankPayments/Payouts/Show').then(
    (r) => r.DatevExportPayoutsShow,
  ),
);
export const AccountingExportIncomingsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "AccountingExportIncomingsPage" */ 'components/Accounting/Export/Incoming/List').then(
    (r) => r.AccountingExportIncomings,
  ),
);
export const AccountingExportIncomingsShowPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "AccountingExportIncomingsShowPage" */ 'components/Accounting/Export/Incoming/Show').then(
    (r) => r.AccountingExportIncomingsShow,
  ),
);
export const AccountingExportOutcomingShowPage = lazyLoadPage(() =>
  import(
    /* webpackChunkName: "AccountingExportOutcomingShowPage" */ 'components/Accounting/Export/Outcoming/Show'
  ).then((r) => r.AccountingExportOutcomingShow),
);

export const BankPaymentsImportsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "BankPaymentsImportsPage" */ 'components/BankPayments/Imports/index').then(
    (r) => r.BankPaymentsImports,
  ),
);
export const BankPaymentsImportPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "BankPaymentsImportPage" */ 'components/BankPayments/Imports/Import').then(
    (r) => r.BankPaymentsImport,
  ),
);
export const BankPaymentsExportsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "BankPaymentsExportsPage" */ 'components/BankPayments/Exports/index').then(
    (r) => r.BankPaymentsExports,
  ),
);
export const BankPaymentsExportPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "BankPaymentsExportPage" */ 'components/BankPayments/Exports/Export').then(
    (r) => r.BankPaymentsExport,
  ),
);

export const OutcomingInvoicesPage = lazyLoadPage(() =>
  import(
    /* webpackChunkName: "OutcomingInvoicesPage" */ 'components/Workspaces/General/OutcomingInvoices/OutcomingInvoices'
  ).then((r) => r.OutcomingInvoices),
);
export const LogisticInvoicesPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "LogisticInvoicesPage" */ 'components/Workspaces/General/LogisticInvoices/index').then(
    (r) => r.LogisticInvoices,
  ),
);
export const BankPaymentsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "BankPaymentsPage" */ 'components/Workspaces/General/BankPayments/BankPayments').then(
    (r) => r.BankPayments,
  ),
);
export const BankPaymentsDraftPage = lazyLoadPage(() =>
  import(
    /* webpackChunkName: "BankPaymentsDraftPage" */ 'components/Workspaces/General/BankPaymentsDraft/BankPaymentsDraft'
  ).then((r) => r.BankPaymentsDraft),
);
export const ProvidersPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "ProvidersPage" */ 'components/Workspaces/General/Providers/Providers').then(
    (r) => r.Providers,
  ),
);
export const QualityControlsPage = lazyLoadPage(() =>
  import(
    /* webpackChunkName: "QualityControlsPage" */ 'components/Workspaces/General/QualityControls/QualityControls'
  ).then((r) => r.QualityControls),
);
export const BillingAccountsPage = lazyLoadPage(() =>
  import(
    /* webpackChunkName: "BillingAccountsPage" */ 'components/Workspaces/General/BillingAccounts/BillingAccounts'
  ).then((r) => r.BillingAccounts),
);
export const ShippingAddressesPage = lazyLoadPage(() =>
  import(
    /* webpackChunkName: "ShippingAddressesPage" */ 'components/Workspaces/General/ShippingAddresses/ShippingAddresses'
  ).then((r) => r.ShippingAddresses),
);
export const StoragesPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "StoragesPage" */ 'components/Workspaces/General/Storages/Storages').then(
    (r) => r.Storages,
  ),
);
export const PaymentsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "PaymentsPage" */ 'components/Workspaces/General/Payments/Payments').then(
    (r) => r.Payments,
  ),
);
export const RefundsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "RefundsPage" */ 'components/Workspaces/General/Refunds/Refunds').then((r) => r.Refunds),
);
export const OrganizationBalancesPage = lazyLoadPage(() =>
  import(
    /* webpackChunkName: "OrganizationBalancesPage" */ 'components/Workspaces/General/OrganizationBalances/OrganizationBalances'
  ).then((r) => r.OrganizationBalances),
);
export const OrganizationsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "OrganizationsPage" */ 'components/Workspaces/General/Organizations/Organizations').then(
    (r) => r.Organizations,
  ),
);
export const DealsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "DealsPage" */ 'components/Workspaces/General/Deals/Deals').then((r) => r.Deals),
);
export const FrameworksPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "DealsPage" */ 'components/Workspaces/General/Frameworks/Frameworks').then(
    (r) => r.Frameworks,
  ),
);
export const PersonsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "PersonsPage" */ 'components/Workspaces/General/Persons/Persons').then((r) => r.Persons),
);
export const EnquiriesPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "EnquiriesPage" */ 'components/Workspaces/General/Enquiries/Enquiries').then(
    (r) => r.Enquiries,
  ),
);
export const RfqsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "RfqsPage" */ 'components/Workspaces/General/Rfqs/Rfqs').then((r) => r.Rfqs),
);
export const RfqOffersPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "RfqOffersPage" */ 'components/Workspaces/General/RfqOffers/RfqOffers').then(
    (r) => r.RfqOffers,
  ),
);
export const JobsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "JobsPage" */ 'components/Workspaces/General/Jobs/Jobs').then((r) => r.Jobs),
);
export const ClaimsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "ClaimsPage" */ 'components/Workspaces/General/Claims/Claims').then((r) => r.Claims),
);
export const PayoutsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "PayoutsPage" */ 'components/Workspaces/General/Payouts/Payouts').then((r) => r.Payouts),
);
export const UsersPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "UsersPage" */ 'components/Workspaces/General/Users/Users').then((r) => r.Users),
);
export const TeamsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "TeamsPage" */ 'pages/teams/ui/TeamsPage/TeamsPage').then((r) => r.TeamsPage),
);
export const MessagesPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "MessagesPage" */ 'components/Workspaces/General/Messages/Messages').then(
    (r) => r.Messages,
  ),
);
export const EmailMessagesPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "EmailMessagesPage" */ 'components/Workspaces/General/EmailMessages/EmailMessages').then(
    (r) => r.EmailMessages,
  ),
);
export const TasksPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "TasksPage" */ 'components/Workspaces/General/Tasks/Tasks').then((r) => r.Tasks),
);
export const MessageSubscriptionsPage = lazyLoadPage(() =>
  import(
    /* webpackChunkName: "MessageSubscriptionsPage" */ 'components/Workspaces/General/MessageSubscriptions/MessageSubscriptions'
  ).then((r) => r.MessageSubscriptions),
);
export const CouponsEUPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "CouponsEUPage" */ 'components/Workspaces/General/Coupons/eu/Coupons').then(
    (r) => r.Coupons,
  ),
);
export const CouponsRegionsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "CouponsRegionsPage" */ 'components/Workspaces/General/Coupons/regions/Coupons').then(
    (r) => r.Coupons,
  ),
);
export const OrdersPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "OrdersPage" */ 'components/Workspaces/Drawings/Orders/Orders').then((r) => r.Orders),
);
export const QuotesPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "QuotesPage" */ 'components/Workspaces/Drawings/Quotes/Quotes').then((r) => r.Quotes),
);
export const LogisticsShippingsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "LogisticsShippingsPage" */ 'components/Workspaces/Logistics/Shippings/Shippings').then(
    (r) => r.LogisticsShippings,
  ),
);
export const HsCodesPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "HsCodesPage" */ 'components/Workspaces/General/HsCodes/HsCodes').then((r) => r.HsCodes),
);
export const PartnersPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "PartnersPage" */ 'components/Workspaces/RFQ/Partners/index').then((r) => r.Partners),
);

export const BillingAccountPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "BillingAccountPage" */ 'components/BillingAccountPage/index').then(
    (r) => r.BillingAccountPage,
  ),
);

export const BillingAccountBalancePage = lazyLoadPage(() =>
  import(/* webpackChunkName: "BillingAccountPage" */ 'components/BillingAccountPage/BillingAccountBalance/index').then(
    (r) => r.BillingAccountBalance,
  ),
);

export const StorageInventoryNotePage = lazyLoadPage(() =>
  import(/* webpackChunkName: "StorageInventoryNotePage" */ 'components/Deal/Storage/InventoryNote').then(
    (r) => r.StorageInventoryNote,
  ),
);

export const TrackingEventsPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "TrackingEventsPage" */ 'components/Backoffice/TrackingEvents/index').then(
    (r) => r.TrackingEvents,
  ),
);

export const ProfilePage = lazyLoadPage(() =>
  import(/* webpackChunkName: "ProfilePage" */ 'components/User/Profile/Profile').then((r) => r.Profile),
);

export const RolesPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "RolesPage" */ 'components/Roles/Roles').then((r) => r.Roles),
);

export const FilterGroupPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "FilterGroups" */ 'components/FilterGroups').then((r) => r.FilterGroups),
);

export const TemplatesPlaceholdersPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "TemplatesPlaceholdersPage" */ 'components/TemplatesPlaceholdersPage').then(
    (r) => r.TemplatesPlaceholdersPage,
  ),
);

export const NewLogisticInvoicePage = lazyLoadPage(() =>
  import(/* webpackChunkName: "NewLogisticInvoicePage" */ 'components/LogisticInvoice/New/index').then(
    (r) => r.NewLogisticInvoice,
  ),
);
export const EditLogisticInvoicePage = lazyLoadPage(() =>
  import(/* webpackChunkName: "EditLogisticInvoicePage" */ 'components/LogisticInvoice/Edit/index').then(
    (r) => r.EditLogisticInvoice,
  ),
);
export const MessageCenterPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "MessageCenterPage" */ 'components/MessageCenterPage/index').then(
    (r) => r.MessageCenterPage,
  ),
);
export const TemplatesPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "TemplatesPage" */ 'components/TemplatesPage/index').then((r) => r.TemplatesPage),
);

// Note(pkocherov-xometry): We don't want to lazy load critical error page.
export const ErrorPage = ErrorPageComponent;

export const Parts = lazyLoadPage(() =>
  import(/* webpackChunkName: "Parts" */ 'components/Deal/Parts').then((r) => r.Parts),
);
export const DealOverview = lazyLoadPage(() =>
  import(/* webpackChunkName: "DealOverview" */ 'components/Deal/Overview').then((r) => r.DealOverview),
);

export const EditEnquiry = lazyLoadPage(() =>
  import(/* webpackChunkName: "EditEnquiry" */ 'components/Deal/Enquiries/Edit/Edit').then((r) => r.EditEnquiry),
);

export const SendRfq = lazyLoadPage(() =>
  import(/* webpackChunkName: "SendRfq" */ 'components/Deal/Enquiries/SendRfq').then((r) => r.SendRfq),
);

export const OrderConfirmationEdit = lazyLoadPage(() =>
  import(/* webpackChunkName: "OrderConfirmationEdit" */ 'components/Deal/OrderConfirmation/shared').then(
    (r) => r.OrderConfirmationEdit,
  ),
);
export const OrderConfirmationNew = lazyLoadPage(() =>
  import(/* webpackChunkName: "OrderConfirmationNew" */ 'components/Deal/OrderConfirmation/shared').then(
    (r) => r.OrderConfirmationNew,
  ),
);
export const OrderConfirmation = lazyLoadPage(() =>
  import(/* webpackChunkName: "OrderConfirmation" */ 'components/Deal/OrderConfirmation').then(
    (r) => r.OrderConfirmation,
  ),
);

export const HsCodes = lazyLoadPage(() =>
  import(/* webpackChunkName: "HsCodes" */ 'components/Deal/HsCodes/HsCodes').then((r) => r.HsCodes),
);

export const EditPayment = lazyLoadPage(() =>
  import(/* webpackChunkName: "EditPayment" */ 'components/Deal/Payment/Edit').then((r) => r.EditPayment),
);
export const NewPayment = lazyLoadPage(() =>
  import(/* webpackChunkName: "NewPayment" */ 'components/Deal/Payment/New').then((r) => r.default),
);

export const TransferPayment = lazyLoadPage(() =>
  import(/* webpackChunkName: "TransferPayment" */ 'components/Deal/Payment/New/TransferPayment').then(
    (r) => r.TransferPayment,
  ),
);

export const EditInvoice = lazyLoadPage(() =>
  import(/* webpackChunkName: "EditInvoice" */ 'components/Deal/OutcomingInvoice/Edit/EditInvoice').then(
    (r) => r.EditInvoice,
  ),
);
export const NewInvoice = lazyLoadPage(() =>
  import(/* webpackChunkName: "NewInvoice" */ 'components/Deal/OutcomingInvoice/New/NewInvoice').then(
    (r) => r.NewInvoice,
  ),
);
export const FixPublishedInvoice = lazyLoadPage(() =>
  import(/* webpackChunkName: "FixPublished" */ 'components/Deal/OutcomingInvoice/Edit/FixPublished').then(
    (r) => r.FixPublished,
  ),
);
export const PublishedInvoice = lazyLoadPage(() =>
  import(/* webpackChunkName: "PublishedInvoice" */ 'components/Deal/OutcomingInvoice/Edit/Published').then(
    (r) => r.PublishedInvoice,
  ),
);

export const NewProviderOrder = lazyLoadPage(() =>
  import(/* webpackChunkName: "NewProviderOrder" */ 'components/Deal/providerOrder/NewProviderOrder').then(
    (r) => r.NewProviderOrder,
  ),
);
export const EditProviderOrder = lazyLoadPage(() =>
  import(/* webpackChunkName: "EditProviderOrder" */ 'components/Deal/providerOrder/EditProviderOrder').then(
    (r) => r.EditProviderOrder,
  ),
);

export const NewIncomingInvoice = lazyLoadPage(() =>
  import(/* webpackChunkName: "NewIncomingInvoice" */ 'components/Deal/IncomingInvoice/New').then(
    (r) => r.NewIncomingInvoice,
  ),
);
export const EditIncomingInvoice = lazyLoadPage(() =>
  import(/* webpackChunkName: "EditIncomingInvoice" */ 'components/Deal/IncomingInvoice/Edit').then(
    (r) => r.EditIncomingInvoice,
  ),
);

export const NewQualityControl = lazyLoadPage(() =>
  import(/* webpackChunkName: "NewQualityControl" */ 'components/Deal/QualityControl/New').then(
    (r) => r.NewQualityControl,
  ),
);
export const EditQualityControl = lazyLoadPage(() =>
  import(/* webpackChunkName: "EditQualityControl" */ 'components/Deal/QualityControl/Edit').then(
    (r) => r.EditQualityControl,
  ),
);
export const EditPositionQualityControl = lazyLoadPage(() =>
  import(/* webpackChunkName: "EditPosition" */ 'components/Deal/QualityControl/EditPosition').then(
    (r) => r.EditPosition,
  ),
);

export const NewJob = lazyLoadPage(() =>
  import(/* webpackChunkName: "NewJob" */ 'components/Deal/Job/New').then((r) => r.NewJob),
);
export const EditJob = lazyLoadPage(() =>
  import(/* webpackChunkName: "EditJob" */ 'components/Deal/Job/Edit').then((r) => r.EditJob),
);
export const NewJobOffer = lazyLoadPage(() =>
  import(/* webpackChunkName: "NewJobOffer" */ 'components/Deal/Job/Offer/New').then((r) => r.NewJobOffer),
);
export const EditJobOffer = lazyLoadPage(() =>
  import(/* webpackChunkName: "EditJobOffer" */ 'components/Deal/Job/Offer/Edit').then((r) => r.EditJobOffer),
);
export const SendEmail = lazyLoadPage(() =>
  import(/* webpackChunkName: "SendEmail" */ 'components/Deal/Job/SendEmail').then((r) => r.SendEmail),
);

export const NewShipping = lazyLoadPage(() =>
  import(/* webpackChunkName: "NewShipping" */ 'components/Deal/Shipping/NewShipping').then((r) => r.NewShipping),
);
export const EditShipping = lazyLoadPage(() =>
  import(/* webpackChunkName: "EditShipping" */ 'components/Deal/Shipping/EditShipping').then((r) => r.EditShipping),
);

export const LegacyQuotation = lazyLoadPage(() =>
  import(/* webpackChunkName: "LegacyQuotation" */ 'components/Deal/LegacyQuotation').then((r) => r.LegacyQuotation),
);

export const NewClaim = lazyLoadPage(() =>
  import(/* webpackChunkName: "NewClaim" */ 'components/Deal/Claims/NewClaim').then((r) => r.NewClaim),
);
export const EditClaim = lazyLoadPage(() =>
  import(/* webpackChunkName: "EditClaim" */ 'components/Deal/Claims/Edit').then((r) => r.EditClaim),
);

export const NewStorage = lazyLoadPage(() =>
  import(/* webpackChunkName: "NewStorage" */ 'components/Deal/Storage/New').then((r) => r.NewStorage),
);
export const EditStorage = lazyLoadPage(() =>
  import(/* webpackChunkName: "EditStorage" */ 'components/Deal/Storage/Edit').then((r) => r.EditStorage),
);

export const ShowDelivery = lazyLoadPage(() =>
  import(/* webpackChunkName: "ShowDelivery" */ 'components/Deal/Delivery/Show').then((r) => r.ShowDelivery),
);

export const NewPayout = lazyLoadPage(() =>
  import(/* webpackChunkName: "NewPayout" */ 'components/Deal/Payouts/New').then((r) => r.NewPayout),
);
export const NewManualPayout = lazyLoadPage(() =>
  import(/* webpackChunkName: "NewManualPayout" */ 'components/Deal/Payouts/NewManual').then((r) => r.NewManualPayout),
);
export const EditPayout = lazyLoadPage(() =>
  import(/* webpackChunkName: "EditPayout" */ 'components/Deal/Payouts/EditPayout').then((r) => r.EditPayout),
);

export const FrameworkAgreement = lazyLoadPage(() =>
  import(/* webpackChunkName: "FrameworkAgreement" */ 'components/Deal/FrameworkAgreement').then(
    (r) => r.FrameworkAgreement,
  ),
);

export const EditRFQ = lazyLoadPage(() =>
  import(/* webpackChunkName: "EditRFQ" */ 'components/Deal/RFQ/EditRFQ').then((r) => r.EditRFQ),
);
export const EditRfqOffer = lazyLoadPage(() =>
  import(/* webpackChunkName: "EditRfqOffer" */ 'components/Deal/RFQ/RfqOffer/EditRfqOffer').then(
    (r) => r.EditRfqOffer,
  ),
);
export const TeamPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "TeamPage" */ 'pages/team').then((r) => r.TeamPage),
);

export const ActivityLogPage = lazyLoadPage(() =>
  import(/* webpackChunkName: "ActivityLogPage" */ 'pages/activity-log').then((r) => r.ActivityLogPage),
);

import { DatePicker as AntdDatePicker } from 'antd';
import clsx from 'clsx';
import React from 'react';

import s from './DatePicker.module.less';

const format = ['DD.MM.YYYY', 'YYYY-MM-DD'];

type Props = React.ComponentProps<typeof AntdDatePicker> & {
  showWeekNumber?: boolean;
};

const DatePicker: React.FC<Props> = (props) => {
  const popupClassName = clsx(props.popupClassName, {
    [s.popup]: props.showWeekNumber,
  });

  const className = clsx(props.className, {
    [s.datePicker]: props.showWeekNumber,
  });

  return (
    <AntdDatePicker
      format={format}
      {...props}
      popupClassName={popupClassName}
      className={className}
      dateRender={
        props.showWeekNumber
          ? (current) => {
              const style: React.CSSProperties = {};
              const isFirstWeekDay = current.weekday() === 0;
              const weekNumber = isFirstWeekDay ? current.isoWeek() : null;

              return (
                <div className="ant-picker-cell-inner" style={style}>
                  {weekNumber && <div className={s.weekNumber}>{weekNumber}</div>}
                  {current.date()}
                </div>
              );
            }
          : props.dateRender
      }
    />
  );
};

export default DatePicker;

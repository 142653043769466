import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AuthorizeActionQueryVariables = Types.Exact<{
  group: Types.Scalars['String']['input'];
  action: Types.Scalars['String']['input'];
  subjectClass?: Types.InputMaybe<Types.Scalars['String']['input']>;
  subjectId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type AuthorizeActionQuery = { __typename?: 'Query', authorizeAction: { __typename?: 'Authorization', authorized: boolean } };


export const AuthorizeActionDocument = gql`
    query authorizeAction($group: String!, $action: String!, $subjectClass: String, $subjectId: Int) {
  authorizeAction(
    group: $group
    action: $action
    subjectClass: $subjectClass
    subjectId: $subjectId
  ) {
    authorized
  }
}
    `;

/**
 * __useAuthorizeActionQuery__
 *
 * To run a query within a React component, call `useAuthorizeActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorizeActionQuery({
 *   variables: {
 *      group: // value for 'group'
 *      action: // value for 'action'
 *      subjectClass: // value for 'subjectClass'
 *      subjectId: // value for 'subjectId'
 *   },
 * });
 */
export function useAuthorizeActionQuery(baseOptions: Apollo.QueryHookOptions<AuthorizeActionQuery, AuthorizeActionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthorizeActionQuery, AuthorizeActionQueryVariables>(AuthorizeActionDocument, options);
      }
export function useAuthorizeActionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthorizeActionQuery, AuthorizeActionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthorizeActionQuery, AuthorizeActionQueryVariables>(AuthorizeActionDocument, options);
        }
export function useAuthorizeActionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AuthorizeActionQuery, AuthorizeActionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AuthorizeActionQuery, AuthorizeActionQueryVariables>(AuthorizeActionDocument, options);
        }
export type AuthorizeActionQueryHookResult = ReturnType<typeof useAuthorizeActionQuery>;
export type AuthorizeActionLazyQueryHookResult = ReturnType<typeof useAuthorizeActionLazyQuery>;
export type AuthorizeActionSuspenseQueryHookResult = ReturnType<typeof useAuthorizeActionSuspenseQuery>;
export type AuthorizeActionQueryResult = Apollo.QueryResult<AuthorizeActionQuery, AuthorizeActionQueryVariables>;
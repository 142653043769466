import { XOMETRY_VAT_ENABLED } from 'config/regions/features';
import { OrderConfirmation } from 'interfaces';
import { isEqual, omit } from 'lodash-es';
import { toJS } from 'mobx';
import moment from 'moment';

import { OCFormValues } from './ocFormValues';

export const getInitialValues = (oc: OrderConfirmation) => {
  const hasDeliveryTerms = !!oc.deliveryTerms;

  const defaultValues = {
    deliveryTerms: hasDeliveryTerms ? oc.deliveryTerms : undefined,
    deliveryDate: hasDeliveryTerms ? undefined : oc.deliveryDate ? moment(oc.deliveryDate) : undefined,
    paymentTerms: oc.paymentTerms,
    paymentTermsDays: oc.paymentTermsDays,
    paymentTermsType: oc.paymentTermsType,
    publishedBy: oc.publishedBy,
    publishedAt: oc.publishedAt ? moment(oc.publishedAt) : null,
    lots: toJS(oc.lots),
    services: oc.services,
    transportTerms: oc.transportTerms,
    prepayRequired: oc.prepayRequired,
    confirmDate: moment(oc.confirmDate),
    billingAccount: oc.billingAccount,
    shippingAddress: oc.shippingAddress,
    positions: undefined,
  };

  const defaultValuesWithVat = {
    ...defaultValues,
    xometryVatNumberId: oc.xometryVatNumber?.id,
  };

  const initialValues: OCFormValues = XOMETRY_VAT_ENABLED ? defaultValuesWithVat : defaultValues;

  return initialValues;
};

export const compareValues = (initialValues: OCFormValues, formValues: OCFormValues) => {
  const oldValues = omit(
    toJS(initialValues, {
      recurseEverything: true,
    }),
    ['xometryVatNumberId'],
  );

  const newValues = omit(
    toJS(formValues, {
      recurseEverything: true,
    }),
    ['xometryVatNumberId'],
  );

  return !isEqual(oldValues, newValues);
};

import { Form, Select } from 'antd';
import { Button } from 'components/UI';
import DatePicker from 'components/UI/Form/DatePicker';
import { Modal, ModalTitle } from 'components/UI/Modal/styled';
import { IProvider } from 'interfaces/graphql';
import moment, { Moment } from 'moment';
import React, { FC } from 'react';
import { useProvidersUpdateMutation } from 'utils/graphql/mutations/__generated__/providersUpdate';
import { useGetUsersOptions } from 'utils/hooks/useGetUsers';

interface UpdatePnmModalProps {
  visible: boolean;
  onCancel: () => void;
  provider: IProvider;
}

interface UpdatePnmFormValues {
  partnerNetworkManagerId: number;
  partnerNetworkManagerFreezeUntil: Moment;
}

export const UpdatePnmModal: FC<UpdatePnmModalProps> = ({ visible, onCancel, provider }) => {
  const { options } = useGetUsersOptions({ roleName: 'partners_manager' });
  const [providersUpdate, { loading: saveLoading }] = useProvidersUpdateMutation();
  const [form] = Form.useForm<UpdatePnmFormValues>();
  const onFinish = async (values: UpdatePnmFormValues) => {
    await providersUpdate({
      variables: {
        input: {
          id: String(provider.id),
          attributes: {
            partnerNetworkManagerId: String(values.partnerNetworkManagerId),
            partnerNetworkManagerFreezeUntil: values?.partnerNetworkManagerFreezeUntil?.format() || null,
          },
        },
      },
    });
    onCancel();
  };

  return (
    <Modal
      title={<ModalTitle>Partner network manager</ModalTitle>}
      open={visible}
      footer={false}
      onCancel={onCancel}
      destroyOnClose
    >
      <Form
        form={form}
        name="updatePnmForm"
        onFinish={(values) => void onFinish(values)}
        layout="vertical"
        initialValues={{
          partnerNetworkManagerId: provider.partnerNetworkManager?.id,
          partnerNetworkManagerFreezeUntil: provider.partnerNetworkManagerFreezeUntil
            ? moment(provider.partnerNetworkManagerFreezeUntil)
            : null,
        }}
      >
        <Form.Item
          label="Partner network manager:"
          className="m-b-xs"
          name="partnerNetworkManagerId"
          rules={[{ required: true, message: "Can't be blank" }]}
        >
          <Select options={options} showSearch />
        </Form.Item>
        <Form.Item label="Freeze until:" name="partnerNetworkManagerFreezeUntil">
          <DatePicker />
        </Form.Item>
        <div className="text-right">
          <Button className="m-r-sm" loading={saveLoading} onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={saveLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

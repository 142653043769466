import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';

interface Props {
  src: string;
  className?: string;
}

const init = (src: string, element?: HTMLDivElement | null) => {
  if (element) {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('id', 'pdfjs_document_viewer');
    iframe.src = `/pdfjs/web/viewer.html?file=${encodeURIComponent(src)}`;
    iframe.onload = function () {
      const fullScreenButton = iframe?.contentWindow?.document.getElementById('presentationMode');
      fullScreenButton?.addEventListener('click', () => {
        void iframe.requestFullscreen();
      });
      const secondaryFullScreenButton = iframe?.contentWindow?.document.getElementById('secondaryPresentationMode');
      secondaryFullScreenButton?.addEventListener('click', () => {
        void iframe.requestFullscreen();
      });

      // hide open file and view bookmark buttons
      const openFile = iframe.contentWindow?.document.getElementById('openFile');
      const viewBookmark = iframe.contentWindow?.document.getElementById('viewBookmark');

      if (openFile) {
        openFile.style.display = 'none';
      }

      if (viewBookmark) {
        viewBookmark.style.display = 'none';
      }
    };

    element.appendChild(iframe);
  }
};

const PdfViewer: React.FC<Props> = ({ src, className }) => {
  const viewerRef = useRef(null);

  useEffect(() => {
    init(src, viewerRef.current);
  }, [src]);

  return <div className={clsx(className, 'pdf-viewer')} ref={viewerRef} id="viewer" />;
};

export default PdfViewer;

import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { ErpPermissionFragmentFragmentDoc } from './erpPermissionFragment';
export type UserPermissionFragment = { __typename?: 'UserPermission', id: number, expiresAt?: string | null, erpPermission: { __typename?: 'ErpPermission', id: number, group: string, action: string, name: string, label?: string | null } };

export const UserPermissionFragmentDoc = gql`
    fragment userPermission on UserPermission {
  id
  expiresAt
  erpPermission {
    ...erpPermissionFragment
  }
}
    ${ErpPermissionFragmentFragmentDoc}`;
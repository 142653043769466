import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
export type MoneyFragmentFragment = { __typename?: 'Money', amount: string, amountCents: string, currencyCode: Types.CurrencyEnum };

export const MoneyFragmentFragmentDoc = gql`
    fragment moneyFragment on Money {
  amount
  amountCents
  currencyCode
}
    `;
import { TypeNameEnum } from '__generated__/types';
import { ServiceType } from 'interfaces';

import certificates from './certificates';

export const packagingTranslated = 'Packaging / Verpackung';

const measurementProtocols = {
  value: TypeNameEnum.MeasurementProtocols,
  label: 'Measurement Protocols',
  defaultName: '',
  children: [
    {
      value: 'standard',
      label: 'Standard Measurement Protocol',
      defaultName: 'Standard Measurement Protocol',
    },
    {
      value: 'fair',
      label: 'First Article Inspection Report (FAIR)',
      defaultName: 'First Article Inspection Report (FAIR)',
    },
    {
      value: 'cmm',
      label: 'CMM Report',
      defaultName: 'CMM Report',
    },
  ],
};

const otherService = {
  value: TypeNameEnum.Other,
  label: 'Other',
  defaultName: '(please specify)',
};

const materialService = {
  value: TypeNameEnum.Material,
  label: 'Material',
  defaultName: 'Material',
};

const transportService = {
  value: TypeNameEnum.Transport,
  label: 'Transport',
  defaultName: 'Transportation cost',
};

const partsService = {
  value: TypeNameEnum.Parts,
  label: 'Parts',
  defaultName: 'Produced parts',
};

const discountService = {
  value: TypeNameEnum.Discount,
  label: 'Discount',
  defaultName: 'Discount (please specify)',
};

const customClearanceService = {
  value: TypeNameEnum.CustomClearance,
  label: 'Custom Clearance',
  defaultName: 'Custom clearance',
};

const correctionService = {
  value: TypeNameEnum.Correction,
  label: 'Correction',
  defaultName: 'Correction (please specify)',
};

const serviceService = {
  value: TypeNameEnum.Service,
  label: 'Service',
  defaultName: 'Service (please specify)',
};

export const packagingService = {
  value: TypeNameEnum.Packaging,
  label: 'Packaging',
  defaultName: 'Packaging',
};

export const imToolsService = {
  value: TypeNameEnum.ImTools,
  label: 'Molding Tool',
  defaultName: 'Molding Tool',
};

export const xpointsService = {
  value: 'x_points',
  label: 'X-points order payment',
  defaultName: 'Loyalty Discount via X-points',
};

export const OCServices = [
  {
    ...packagingService,
    defaultName: packagingTranslated,
  },
  {
    ...transportService,
    defaultName: 'Transportation cost / Versand',
  },
  {
    ...customClearanceService,
    defaultName: 'Custom clearance / Verzollung',
  },
  {
    value: TypeNameEnum.Coupon,
    label: 'Coupon',
    defaultName: 'Coupon code (please specify)',
  },
  discountService,
  {
    value: TypeNameEnum.DiscountPrepay,
    label: 'Prepay Discount',
    defaultName: 'Prepay Discount',
  },
  {
    value: TypeNameEnum.MinOrderCharge,
    label: 'Min Order Charge',
    defaultName: 'Minimum Quantity Surcharge / Mindermengenzuschlag',
  },
  certificates,
  measurementProtocols,
  {
    ...serviceService,
    defaultName: 'Service / Bedienung',
  },
  {
    ...correctionService,
    defaultName: 'Correction / Korrektur',
  },
  materialService,
  otherService,
  imToolsService,
  xpointsService,
];

export interface OIOption {
  value: TypeNameEnum | ServiceType;
  label: string;
  defaultName: string;
  children?: Array<{
    value: string;
    label: string;
    defaultName?: string;
  }>;
}

export const OIServices: OIOption[] = [
  {
    ...packagingService,
    defaultName: packagingTranslated,
  },
  {
    ...transportService,
    defaultName: 'Transportation cost / Versand',
  },
  {
    ...customClearanceService,
    defaultName: 'Custom clearance / Verzollung',
  },
  {
    value: TypeNameEnum.Coupon,
    label: 'Coupon',
    defaultName: 'Coupon code (please specify)',
  },
  discountService,
  {
    value: TypeNameEnum.DiscountPrepay,
    label: 'Prepay Discount',
    defaultName: 'Prepay Discount',
  },
  {
    value: TypeNameEnum.MinOrderCharge,
    label: 'Min Order Charge',
    defaultName: 'Minimum Quantity Surcharge / Mindermengenzuschlag',
  },
  certificates,
  measurementProtocols,
  {
    ...serviceService,
    defaultName: 'Service / Bedienung',
  },
  {
    ...correctionService,
    defaultName: 'Correction / Korrektur',
  },
  materialService,
  otherService,
  partsService,
  imToolsService,
];

export const POServices = [
  packagingService,
  transportService,
  customClearanceService,
  discountService,
  certificates,
  measurementProtocols,
  serviceService,
  correctionService,
  materialService,
  otherService,
  imToolsService,
];

import krampusImageClose from 'assets/xmas/krampus-close.png';
import krampusImageMid from 'assets/xmas/krampus-mid.png';
import krampusImageOpen from 'assets/xmas/krampus-open.png';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import s from './Krampus.module.less';

interface Props {
  className?: string;
}

export const Krampus: React.FC<Props> = (props) => {
  const { className } = props;
  const [state, setState] = useState(0);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const blink = () => {
      setState(1);
      setTimeout(() => {
        setState(2);
      }, 100);
      setTimeout(() => {
        setState(1);
      }, 200);
      setTimeout(() => {
        setState(0);
      }, 300);
    };

    const blinkInterval = setInterval(blink, 3000);

    const showTimeout = setTimeout(() => {
      setShow(true);
    }, 1000);

    return () => {
      clearInterval(blinkInterval);
      clearTimeout(showTimeout);
    };
  }, []);

  return (
    <div className={clsx(className, s.root, { [s.show]: show })}>
      <img src={krampusImageOpen} style={{ display: state === 0 ? 'block' : 'none' }} alt="Krampus" />
      <img src={krampusImageMid} style={{ display: state === 1 ? 'block' : 'none' }} alt="Krampus" />
      <img src={krampusImageClose} style={{ display: state === 2 ? 'block' : 'none' }} alt="Krampus" />
    </div>
  );
};

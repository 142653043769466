import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WorkspacePresetsAssignGlobalDefaultPresetMutationVariables = Types.Exact<{
  workspaceName: Types.Scalars['String']['input'];
  presetId: Types.Scalars['ID']['input'];
}>;


export type WorkspacePresetsAssignGlobalDefaultPresetMutation = { __typename?: 'Mutation', workspacePresetsAssignGlobalDefaultPreset?: { __typename?: 'WorkspacePresetsAssignGlobalDefaultPresetPayload', success: boolean } | null };


export const WorkspacePresetsAssignGlobalDefaultPresetDocument = gql`
    mutation workspacePresetsAssignGlobalDefaultPreset($workspaceName: String!, $presetId: ID!) {
  workspacePresetsAssignGlobalDefaultPreset(
    input: {workspaceName: $workspaceName, presetId: $presetId}
  ) {
    success
  }
}
    `;
export type WorkspacePresetsAssignGlobalDefaultPresetMutationFn = Apollo.MutationFunction<WorkspacePresetsAssignGlobalDefaultPresetMutation, WorkspacePresetsAssignGlobalDefaultPresetMutationVariables>;

/**
 * __useWorkspacePresetsAssignGlobalDefaultPresetMutation__
 *
 * To run a mutation, you first call `useWorkspacePresetsAssignGlobalDefaultPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkspacePresetsAssignGlobalDefaultPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workspacePresetsAssignGlobalDefaultPresetMutation, { data, loading, error }] = useWorkspacePresetsAssignGlobalDefaultPresetMutation({
 *   variables: {
 *      workspaceName: // value for 'workspaceName'
 *      presetId: // value for 'presetId'
 *   },
 * });
 */
export function useWorkspacePresetsAssignGlobalDefaultPresetMutation(baseOptions?: Apollo.MutationHookOptions<WorkspacePresetsAssignGlobalDefaultPresetMutation, WorkspacePresetsAssignGlobalDefaultPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WorkspacePresetsAssignGlobalDefaultPresetMutation, WorkspacePresetsAssignGlobalDefaultPresetMutationVariables>(WorkspacePresetsAssignGlobalDefaultPresetDocument, options);
      }
export type WorkspacePresetsAssignGlobalDefaultPresetMutationHookResult = ReturnType<typeof useWorkspacePresetsAssignGlobalDefaultPresetMutation>;
export type WorkspacePresetsAssignGlobalDefaultPresetMutationResult = Apollo.MutationResult<WorkspacePresetsAssignGlobalDefaultPresetMutation>;
export type WorkspacePresetsAssignGlobalDefaultPresetMutationOptions = Apollo.BaseMutationOptions<WorkspacePresetsAssignGlobalDefaultPresetMutation, WorkspacePresetsAssignGlobalDefaultPresetMutationVariables>;
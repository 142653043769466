import { ServiceType } from 'interfaces';

export default {
  value: ServiceType.Certificates,
  label: 'Certificates',
  defaultName: '',
  children: [
    {
      value: 'cert_compliance_2_1',
      label: 'Certificate of compliance (type 2.1 according to EN 10204)',
      defaultName: 'Certificate of compliance (type 2.1 according to EN 10204)',
    },
    {
      value: 'cert_compliance_2_2',
      label: 'Certificate of compliance with order type 2.2 according to EN 10204',
      defaultName: 'Certificate of compliance with order type 2.2 according to EN 10204',
    },
    {
      value: 'cert_materials_3_1',
      label: 'Raw material certificate (metals)',
      defaultName: 'Raw material certificate (metals)',
    },
    {
      value: 'cert_materials_plastic',
      label: 'Raw material certificate (plastics)',
      defaultName: 'Raw material certificate (plastics)',
    },
    {
      value: 'cert_materials_3_2',
      label: 'Material certificate type 3.2 according to EN 10204',
      defaultName: 'Material certificate type 3.2 according to EN 10204',
    },
    {
      value: 'cert_heat_treatment',
      label: 'Heat treatment protocols',
      defaultName: 'Heat treatment protocols',
    },
    {
      value: 'cert_rohs',
      label: 'Restriction of Hazardous Substances',
      defaultName: 'Restriction of Hazardous Substances',
    },
  ],
};

import { AxiosError } from 'axios';
import { PartCloneTo } from 'components/Deal/Parts/BulkActions/Clone/Form';
import { CreatePartLotFormValues } from 'components/Deal/Parts/CollapseBody/AddLotOpener/Form';
import { customerObjectsBulkCreate, updateCustomerObject } from 'components/Deal/Parts/customer-object/api';
import { CustomerObjectFormValues } from 'components/Deal/Parts/customer-object/CustomerObjectForm';
import { UploadedFile } from 'components/Deal/Parts/Edit/Form/Drawings/AddDrawingFromPC';
import Notification from 'components/UI/Notification';
import {
  AttacheFileContext,
  IAttachedFile,
  NewPart,
  Part,
  PartCustomerLot,
  PartDimensions,
  PartFormValues,
  PartsSortOptions,
  PartType,
  PreloadedFile,
} from 'interfaces';
// eslint-disable-next-line no-restricted-imports
import { concat, each, filter, find, findIndex, first, get, includes, map, reject, trim } from 'lodash-es';
import { action, computed, observable } from 'mobx';
import { routesApi } from 'routes/api';
import { sortBy } from 'stores/Parts/utils';
import { RootStore } from 'stores/RootStore';
import { ModalStore } from 'stores/shared/ModalStore';
import { requestStatus } from 'stores/utils';
import omsApi, { camelizeKeys, decamelizeKeys, requestBuilder } from 'utils/axios';

import { EditPartModalStore } from './EditPartModalStore';
import { PanelStore } from './PanelStore';

interface DrawingsChanges {
  [id: string]: UploadedFile;
}

interface CleanedUpload {
  parentId?: number;
  isUid?: boolean;
  isExisting?: boolean;
  file?: UploadedFile;
  description?: string;
}

interface EditPartDrawings {
  drawings: UploadedFile[];
  nonDrawings: UploadedFile[];
  partAttachments: IAttachedFile[];
  preloadedOtherFiles: PreloadedFile[];
  changes: DrawingsChanges;
  cleanedUploads: CleanedUpload[];
  preQuoteUploads: UploadedFile[];
  ids: number[];
  idsDestroy: number[];
}

const nullifyStringIfEmpty = (value?: string) => {
  if (value === undefined) {
    return undefined;
  }

  return trim(value) === '' ? null : value;
};

const nullifyValueIfConditionFalse = (value?: number, condition?: boolean) => {
  if (condition === undefined) {
    return undefined;
  }

  return condition ? value : null;
};

class PartsStore {
  rootStore: RootStore;
  editPartDetailsModalStore = new EditPartModalStore(this);
  editCustomerObjectModal = new ModalStore();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable editPartDrawings: EditPartDrawings = {
    drawings: [],
    nonDrawings: [],
    cleanedUploads: [],
    preQuoteUploads: [],
    partAttachments: [],
    changes: {},
    ids: [],
    preloadedOtherFiles: [],
    idsDestroy: [],
  };

  @observable auditedBy: string | undefined = undefined;

  @observable showOnlyOrderedParts = false;

  @observable partsLoadStatus = requestStatus();

  @observable partsCreateStatus = requestStatus();

  @observable isFetchingZipOriginal = false;

  @observable isFetchingZipCleaned = false;

  @observable isRegenerationInProcess = false;

  @observable parts?: Part[] = undefined;

  @observable customerObjects: Part[] = [];

  @observable sortedBy: PartsSortOptions =
    (localStorage.getItem('partsSortedBy') as PartsSortOptions) ?? PartsSortOptions.ID_ASC;

  partPanels = new PanelStore();
  customerObjectPanels = new PanelStore();

  @action setEditPartFiles = (files: UploadedFile[], scope: 'drawings' | 'nonDrawings', remove?: boolean) => {
    const baseAttr = get(first(files), 'id') !== undefined ? 'id' : 'uid';

    if (remove) {
      const removeIds = map(files, baseAttr);
      this.editPartDrawings[scope] = reject(this.editPartDrawings[scope], (file) =>
        includes(removeIds, file[baseAttr]),
      );
    } else {
      const addedIds = map(files, baseAttr);
      this.editPartDrawings[scope] = concat(
        reject(this.editPartDrawings[scope], (file) => includes(addedIds, file[baseAttr])),
        files,
      );
    }
  };

  @action setPreloadeOtherFiles = (files: PreloadedFile[]) => {
    this.editPartDrawings.preloadedOtherFiles = files;
  };

  @action reset = () => {
    this.editPartDrawings.drawings = [];
    this.editPartDrawings.nonDrawings = [];
    this.editPartDrawings.cleanedUploads = [];
    this.editPartDrawings.preQuoteUploads = [];
    this.editPartDrawings.partAttachments = [];
    this.editPartDrawings.changes = {};
    this.editPartDrawings.ids = [];
    this.editPartDrawings.preloadedOtherFiles = [];
    this.editPartDrawings.idsDestroy = [];
  };

  @action setCleanedUploads = (cleanedUploads: CleanedUpload[]) => {
    this.editPartDrawings.cleanedUploads = cleanedUploads;
  };

  @action setPreQuoteUploads = (uploads: UploadedFile[]) => {
    this.editPartDrawings.preQuoteUploads = uploads;
  };

  @action toggleShowOnlyOrderedParts = () => {
    this.showOnlyOrderedParts = !this.showOnlyOrderedParts;
  };

  @action setChange = (id: number, file?: UploadedFile) => {
    if (file) {
      this.editPartDrawings.changes[String(id)] = file;
    } else {
      delete this.editPartDrawings.changes[String(id)];
    }
  };

  @action setAuditedBy = (by: string) => {
    this.auditedBy = by;
  };

  @action setIdsDestroy = (ids: number[]) => {
    this.editPartDrawings.idsDestroy = concat(this.editPartDrawings.idsDestroy, ids);
  };

  @action setEditDrawingsPartAttachments = (attachments: IAttachedFile[]) => {
    this.editPartDrawings.partAttachments = [...attachments];
  };

  @action resetEditPart = () => {
    this.editPartDrawings = {
      drawings: [],
      nonDrawings: [],
      cleanedUploads: [],
      preQuoteUploads: [],
      partAttachments: [],
      changes: {},
      preloadedOtherFiles: [],
      ids: [],
      idsDestroy: [],
    };
  };

  @action fetchParts = (dealId: string) => {
    this.partsLoadStatus.isProcessing = true;

    omsApi
      .get(routesApi.dealPartsPath(dealId))
      .then((response) => {
        const parts = camelizeKeys(get(response, 'data.payload') as Part[]);
        this.setParts(parts);
      })
      .catch(() => {
        Notification.Error('Failed to fetch Parts');
      })
      .finally(() => {
        this.finishPartsLoad();
      });
  };

  @action updatePart = async (partId: number, values: PartFormValues) => {
    this.rootStore.pageLoaderStore.enable('Updating Part...');

    const formData = new FormData();
    each(this.editPartDrawings.drawings, (file: UploadedFile) => {
      formData.append('part[drawing_files[][id]]', String(file.id));
      formData.append('part[drawing_files[][file]]', file.id ? 'null' : file);
      formData.append('part[drawing_files[][uid]]', String(file.uid));
    });
    each(this.editPartDrawings.nonDrawings, (file: UploadedFile) => {
      formData.append('part[non_drawing_files[][file]]', file);
    });
    each(this.editPartDrawings.preQuoteUploads, (file: UploadedFile) => {
      formData.append('part[pre_quote_files[][file]]', file);
    });

    each(this.editPartDrawings.changes, (file: UploadedFile, attachmentId: string) => {
      formData.append('part[changes[][id]]', attachmentId);
      formData.append('part[changes[][file]]', file);
    });

    each(this.editPartDrawings.cleanedUploads, (cleanedUpload: CleanedUpload) => {
      formData.append('part[cleaned_uploads[][is_uid]]', String(cleanedUpload.isUid));
      formData.append('part[cleaned_uploads[][is_existing]]', String(cleanedUpload.isExisting));
      formData.append('part[cleaned_uploads[][parent_id]]', String(cleanedUpload.parentId));

      if (cleanedUpload.description) {
        formData.append('part[cleaned_uploads[][description]]', 'cleaning skipped');
      }

      if (cleanedUpload.file) {
        formData.append('part[cleaned_uploads[][file]]', cleanedUpload.file);
      }
    });

    const updateValues = this.mapValues(values);

    updateValues.uploads_ids = this.editPartDrawings.ids;
    updateValues.destroy_ids = this.editPartDrawings.idsDestroy;

    each(updateValues, (value, key) => {
      if (value !== undefined) {
        if (key === 'dimensions' && value) {
          const dimensions = value as PartDimensions;

          Object.entries(dimensions).forEach(([k, v]) => {
            formData.append(`part[dimensions][${k}]`, String(v));
          });

          return;
        }

        formData.append(`part[${key}]`, JSON.stringify(value));
      }
    });

    return omsApi
      .patch(routesApi.partPath(partId), formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        const part = camelizeKeys(get(response, 'data.payload') as Part);
        const partIndex = findIndex(this.parts, { id: part.id });

        if (partIndex !== undefined) {
          const oldPart = get(this.parts, partIndex) as Part;
          this.setPart(partIndex, {
            ...part,
            customerLots: oldPart.customerLots,
          });
        }

        this.editPartDetailsModalStore.openNextPartModal();

        Notification.Success('Part has been updated');
      })
      .catch(() => {
        Notification.Error('Failed to update Part');
      })
      .finally(() => {
        this.finishPartsLoad();
        this.rootStore.pageLoaderStore.disable();
      });
  };

  @action createParts = (dealId: string | number, parts: NewPart[]) => {
    this.partsCreateStatus.isProcessing = true;

    return omsApi
      .post(routesApi.dealPartsBulkCreatePath(dealId), {
        parts: decamelizeKeys(parts),
      })
      .then((response) => {
        const parts = camelizeKeys(get(response, 'data.payload') as Part[]);
        this.mergeParts(parts);
      })
      .catch(() => {
        Notification.Error('Something went wrong!');
      })
      .finally(() => {
        this.finishPartsCreate();
      });
  };

  @action createCustomerObjects = async (dealId: string | number, parts: NewPart[]) => {
    this.partsCreateStatus.isProcessing = true;

    return customerObjectsBulkCreate(dealId, parts)
      .then((response) => {
        Notification.Success('Customer object has been created!');
        this.mergeCustomerObjects(response);
      })
      .catch((err: AxiosError) => {
        Notification.Error('Something went wrong!', err.message);
      })
      .finally(() => {
        this.finishPartsCreate();
      });
  };

  @action updateCustomerObject = async (
    dealId: string | number,
    customerObjectId: string | number,
    values: CustomerObjectFormValues,
  ) => {
    const price = values.customerObjectPrice
      ? {
          amountCents: values.customerObjectPrice?.amountCents,
          currency: values.customerObjectPrice?.currencyCode,
        }
      : undefined;

    return updateCustomerObject(dealId, customerObjectId, {
      name: values.name,
      customerNote: values.customerNote,
      returnRequired: values.returnRequired,
      fileIds: values.attachments,
      position: values.position,
      dimensions: values.dimensions,
      customerObjectPrice: price,
      itemMassKg: values.weightKg,
      comments: values.internalComment,
    })
      .then((newPart) => {
        Notification.Success('Part has been updated');

        this.setCustomerObject(newPart);
      })
      .catch((error: AxiosError) => {
        Notification.Error('Failed to update part', error.message);
      });
  };

  @action bulkUpdate = async (values: Record<string, any>) => {
    this.rootStore.pageLoaderStore.enable('Bulk updating parts...');

    return omsApi
      .patch(routesApi.dealPartsBulkUpdatePath(this.rootStore.dealStore.data.id), {
        parts: {
          ...decamelizeKeys(values),
          ids: map(filter(this.parts, 'selected'), 'id'),
        },
      })
      .then((response) => {
        const parts = camelizeKeys(get(response, 'data.payload') as Part[]);
        each(parts, (part) => {
          this.setPart(findIndex(this.parts, { id: part.id }), part);
        });
        Notification.Success('Successfully updated Parts!');

        return response;
      })
      .catch((err: AxiosError) => {
        Notification.Error('Failed to update Parts!');

        return err;
      })
      .finally(() => {
        this.rootStore.pageLoaderStore.disable();
      });
  };

  @action createLot = async (values: CreatePartLotFormValues, partId: number) => {
    this.rootStore.pageLoaderStore.enable('Creating lot...');

    return omsApi
      .post(routesApi.partsCreateLotPath(partId), {
        lot: decamelizeKeys({
          ...values,
          customerPrice: values.customerPrice === 0 ? null : values.customerPrice,
          providerPrice: values.providerPrice === 0 ? null : values.providerPrice,
        }),
      })
      .then((response) => {
        Notification.Success('Lot has been created!');
        this.setCustomerLots(partId, camelizeKeys(get(response, 'data.payload')));

        return response;
      })
      .catch((err: AxiosError) => {
        Notification.Error('Something went wrong!');

        return err;
      })
      .finally(() => {
        this.rootStore.pageLoaderStore.disable();
      });
  };

  @action setCustomerLots = (partId: number, lots: PartCustomerLot[]): void => {
    const part = find(this.parts, { id: partId });

    if (part) {
      part.customerLots = lots;
    }
  };

  @action loadCustomerLots = async (partId: number) =>
    omsApi
      .get(routesApi.partCustomerLotsPath(partId))
      .then((response) => {
        this.setCustomerLots(partId, camelizeKeys(get(response, 'data.payload')));
      })
      .catch((err: AxiosError) => {
        Notification.Error('Failed to load Customer Lots!');

        return err;
      });

  @action cloneToDeal = async (values: { dealId: string; cloneTo: PartCloneTo }) => {
    this.rootStore.pageLoaderStore.enable('Cloning...');

    return omsApi
      .post<{ response: { payload: string } }>(routesApi.partsClonePath(), {
        pipedrive_id: values.cloneTo === PartCloneTo.NewDeal ? undefined : values.dealId,
        person_id: this.rootStore.dealStore.data.personId,
        parts_ids: map(filter(this.parts, 'selected'), 'id'),
      })
      .then((response) => {
        Notification.Success('Parts have been cloned!');

        return response;
      })
      .catch((err: AxiosError) => {
        Notification.Error('Something went wrong!');

        return err;
      })
      .finally(() => {
        this.rootStore.pageLoaderStore.disable();
      });
  };

  @action zipOriginalPartFiles = (dealId: number) => {
    this.isFetchingZipOriginal = true;

    const request = requestBuilder({ responseType: 'blob' });
    const partIds = (this.parts?.filter((part: Part) => part.selected) || []).map((part: Part) => part.id);

    request
      .get(routesApi.dealPartsZipOriginalPath(dealId), {
        params: { part_ids: partIds },
      })
      .then((response) => {
        const { pipedriveId } = this.rootStore.dealStore.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `d_${pipedriveId || ''}_original.zip`);

        document.body.appendChild(link);

        link.click();
      })
      .catch(() => {
        Notification.Error('An error has occured while compressing files');
      })
      .finally(() => {
        this.isFetchingZipOriginal = false;
      });
  };

  @action zipCleanedPartFiles = (dealId: number) => {
    this.isFetchingZipCleaned = true;

    const request = requestBuilder({ responseType: 'blob' });
    const partIds = (this.parts?.filter((part: Part) => part.selected) || []).map((part: Part) => part.id);

    request
      .get(routesApi.dealPartsZipCleanedPath(dealId), {
        params: { part_ids: partIds },
      })
      .then((response) => {
        const { pipedriveId } = this.rootStore.dealStore.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `d_${pipedriveId || ''}_cleaned.zip`);

        document.body.appendChild(link);

        link.click();
      })
      .catch(() => {
        Notification.Error('An error has occured while compressing files');
      })
      .finally(() => {
        this.isFetchingZipCleaned = false;
      });
  };

  @action setParts = (parts: Part[]): void => {
    const customerObjects = parts.filter((part) => part.partType === PartType.CustomerObject);
    const standardParts = parts.filter((part) => part.partType === PartType.Standard);

    this.customerObjects = customerObjects;
    this.parts = standardParts;
  };

  @action toggleSelect = (partId: number): void => {
    const part = find(this.parts, { id: partId });

    if (part) {
      part.selected = !part.selected;
    }
  };

  @action toggleSelectAll = (selected: boolean): void => {
    each(this.parts, (part) => {
      // eslint-disable-next-line no-param-reassign
      part.selected = selected;
    });
  };

  @action setPart = (index: number, part: Part): void => {
    if (this.parts) {
      this.parts[index] = part;
    }
  };

  @action setCustomerObject = (customerObject: Part): void => {
    const customerObjects = this.customerObjects || [];
    const index = customerObjects.findIndex((c) => c.id === customerObject.id);

    if (index !== -1 && this.customerObjects) {
      this.customerObjects[index] = customerObject;
    } else {
      Notification.Error('Internal error', 'Failed to update customer object. Reload the page to see the changes');
    }
  };

  @action mergeParts = (parts: Part[]): void => {
    this.parts = [...(this.parts || []), ...parts];
  };

  @action mergeCustomerObjects = (customerObjects: Part[]): void => {
    this.customerObjects = [...(this.customerObjects || []), ...customerObjects];
  };

  @action setSortedBy = (sortedBy: PartsSortOptions): void => {
    this.sortedBy = sortedBy;
    localStorage.setItem('partsSortedBy', sortedBy);
  };

  @action finishPartsLoad = (): void => {
    this.partsLoadStatus.isProcessing = false;
    this.partsLoadStatus.isFinished = true;
  };

  @action setRegenrationProcess = (value: boolean): void => {
    this.isRegenerationInProcess = value;
  };

  @action regeneratePreviews = (dealId: number) => {
    this.setRegenrationProcess(true);

    const selectedIds = (this.parts?.filter((part: Part) => part.selected) || []).map((p) => p.id);

    omsApi
      .post(routesApi.dealPartsBulkRegeneratePreviewPath(dealId), {
        ids: selectedIds,
      })
      .then((response) => {
        if (response && response.status === 200) {
          Notification.Success('Regeneration process has been started. Images will appear during few minutes.');
        }
      })
      .catch((err: AxiosError) => {
        Notification.Error("Can't start regenerate process, please inform IT team");

        return err;
      })
      .finally(() => this.setRegenrationProcess(false));
  };

  private mapValues = (values: PartFormValues) => {
    const result: Record<string, any> = {
      position: values.position,
      name: values.name,
      comments: values.comments,
      customer_note: values.customerNote,
      production_remark: values.productionRemark,

      hs_code: values.hsCode,
      hs_code_name: values.hsCodeName,
      hs_code_purpose: values.hsCodePurpose,
      hs_code_state: values.hsCodeState,
      hs_code_confirmed_by: values.hsCodeConfirmedByName,
      hs_code_confirmed_at: values.hsCodeConfirmedAt,

      item_mass_kg: values.weightKg,
      dimensions: values.dimensions,
      post_processing: values.finish,
      material: values.material,

      is_express: values.isExpress,
      no_quote: values.isNoQuote,
      samples_needed: values.isSamplesNeeded,
      samples_quantity: nullifyValueIfConditionFalse(values.samplesQuantity, values.isSamplesNeeded),
      samples_comment: nullifyStringIfEmpty(values.samplesComment),
      drawings_reworked_by: values.drawingsReworkedBy,
      drawings_rework_status: values.drawingsReworkStatus,
      audit_updated_by: values.auditUpdatedBy,
      audit_drawing_rework_task: nullifyStringIfEmpty(values.drawingReworkTask),
      audit_review_remark: nullifyStringIfEmpty(values.auditComment),
      audit_state: values.auditState,

      version_desc: values.versionDesc,

      pre_quoted_comment: values.preQuotedComment,
      pre_quoted: values.isPreQuoted,
      pre_quoted_ticket: values.preQuotedTicket,
      append_files_with: values.appendFilesWith,
      pre_quoted_by_id: nullifyValueIfConditionFalse(values.preQuotedByOption?.value, values.isPreQuoted),
    };

    // if mp changed in form
    if (values.isMpNeeded != null) {
      result.measurement_protocol_needed = values.mpNeeded || null;
    }

    // if audit or drawings form
    if (values.productionRisksTagsOption !== undefined) {
      const tagIds = concat(
        map(values.productionMethodFeaturesTagsOption, 'value'),
        map(values.productionMethodsTagsOption, 'value'),
        map(values.materialsTagsOption, 'value'),
        map(values.productionRisksTagsOption, 'value'),
      );

      result.shift_tag_ids = tagIds;
    }

    return result;
  };

  @action finishPartsCreate = (): void => {
    this.partsCreateStatus.isProcessing = false;
    this.partsCreateStatus.isFinished = true;
  };

  @computed({ keepAlive: true })
  get partsList(): Part[] | undefined {
    const parts = this.showOnlyOrderedParts ? reject(this.parts, { isOrdered: false }) : this.parts;

    return sortBy(parts || [], this.sortedBy);
  }

  @computed
  get customerObjectsList(): Part[] {
    return sortBy(this.customerObjects || [], this.sortedBy);
  }

  @computed
  get editPartDrawingsDefaultWithoutParent() {
    return this.editPartDrawings.partAttachments.filter(
      (file) => file.context === AttacheFileContext.Default && file.parentId == null,
    );
  }

  @computed
  get editPartDrawingsDefaultWithParent() {
    return this.editPartDrawings.partAttachments.filter((file) => file.parentId != null);
  }

  @computed
  get allPartDrawingsAreCleaned(): boolean {
    const cleanedUploads = this.editPartDrawings.cleanedUploads;
    const editPartDrawingsDefaultWithoutParent = this.editPartDrawingsDefaultWithoutParent;
    const editPartDrawingsDefaultWithParent = this.editPartDrawingsDefaultWithParent;
    const drawings = this.editPartDrawings.drawings;

    const cleanedParentIds = cleanedUploads.map((d) => d.parentId);

    const notCleanerDrawings = editPartDrawingsDefaultWithoutParent.filter((file) => {
      const hasCleanedVersion = editPartDrawingsDefaultWithParent.some((f) => {
        return f.parentId === file.id;
      });

      return !(hasCleanedVersion || cleanedParentIds.includes(file.id));
    });
    const notCleanerDrawingsUpload = drawings.filter((file) => {
      const hasCleanedVersion = cleanedUploads.some((f) => {
        return f.parentId === file.id || f.parentId === file.uid;
      });

      return !hasCleanedVersion;
    });

    return notCleanerDrawings.length === 0 && notCleanerDrawingsUpload.length === 0;
  }
}

export default PartsStore;

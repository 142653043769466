import { action, observable } from 'mobx';
import { uniq } from 'utils/listUtils';

export class PanelStore {
  @observable activePanelKeys: string | string[] = [];

  @action setActivePanelKeys = (keys: string | string[]) => {
    this.activePanelKeys = keys;
  };

  @action openPanel = (id: string) => {
    const activePanelKeys = Array.isArray(this.activePanelKeys) ? this.activePanelKeys : [this.activePanelKeys];
    const newKeys = uniq([...activePanelKeys, id]);
    this.activePanelKeys = newKeys;
  };
}

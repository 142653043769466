import { Part } from 'interfaces';
import { routesApi } from 'routes/api';
import api, { camelizeKeys, decamelizeKeys } from 'utils/axios';

interface Response {
  payload: Part[];
}

interface CustomerObjectInput {
  name: string;
  customerNote?: string;
  returnRequired?: boolean;
  fileIds: number[];
}

export const customerObjectsBulkCreate = async (
  dealId: string | number,
  customerObjects: CustomerObjectInput[],
): Promise<Part[]> => {
  const decamelizedInput = decamelizeKeys(customerObjects);
  const response = await api.post<Response>(routesApi.customerObjectsBulkCreatePath(dealId), {
    customer_objects: decamelizedInput,
  });
  const camelizedResponse = camelizeKeys(response.data);

  return camelizedResponse.payload;
};

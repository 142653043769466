import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { MessengerTemplateCategoryFragmentDoc } from '../../fragments/__generated__/messengerTemplateCategory';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplateModalQueryVariables = Types.Exact<{
  entryPoint: Types.TemplateEntryPointEnum;
  templateSourceId: Types.Scalars['ID']['input'];
  templateSourceType: Types.Scalars['String']['input'];
  messageSourceId: Types.Scalars['ID']['input'];
  messageSourceType: Types.Scalars['String']['input'];
}>;


export type TemplateModalQuery = { __typename?: 'Query', messengerTemplateCategories: Array<{ __typename?: 'TemplateCategory', id: string, title: string, channels: Array<Types.TemplateChannelEnum>, templatesGroups: Array<{ __typename?: 'ManagerTemplateGroupType', id: string, constantName?: string | null, name?: string | null, direction: Types.TemplateDirectionEnum, templates: Array<{ __typename?: 'Template', id: string, locale: Types.TemplateLocaleEnum }> }> }>, messengerSource: { __typename?: 'MessageSource', id?: string | null, sourceType: string, sourceId: string, followingUserIds: Array<string> }, messengerSourceRelatedObjects?: { __typename?: 'SourceRelatedObjects', person?: { __typename?: 'Person', id: number, locale?: string | null, organizationId?: string | null } | null, provider?: { __typename?: 'Provider', id: number, primaryContactId?: number | null, locale?: string | null } | null } | null };


export const TemplateModalDocument = gql`
    query templateModal($entryPoint: TemplateEntryPointEnum!, $templateSourceId: ID!, $templateSourceType: String!, $messageSourceId: ID!, $messageSourceType: String!) {
  messengerTemplateCategories(
    attributes: {entryPoint: $entryPoint, sourceId: $templateSourceId, sourceType: $templateSourceType}
  ) {
    ...messengerTemplateCategory
  }
  messengerSource(sourceId: $messageSourceId, sourceType: $messageSourceType) {
    id
    sourceType
    sourceId
    followingUserIds
  }
  messengerSourceRelatedObjects(
    sourceId: $messageSourceId
    sourceType: $messageSourceType
  ) {
    person {
      id
      locale
      organizationId
    }
    provider {
      id
      primaryContactId
      locale
    }
  }
}
    ${MessengerTemplateCategoryFragmentDoc}`;

/**
 * __useTemplateModalQuery__
 *
 * To run a query within a React component, call `useTemplateModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateModalQuery({
 *   variables: {
 *      entryPoint: // value for 'entryPoint'
 *      templateSourceId: // value for 'templateSourceId'
 *      templateSourceType: // value for 'templateSourceType'
 *      messageSourceId: // value for 'messageSourceId'
 *      messageSourceType: // value for 'messageSourceType'
 *   },
 * });
 */
export function useTemplateModalQuery(baseOptions: Apollo.QueryHookOptions<TemplateModalQuery, TemplateModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateModalQuery, TemplateModalQueryVariables>(TemplateModalDocument, options);
      }
export function useTemplateModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateModalQuery, TemplateModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateModalQuery, TemplateModalQueryVariables>(TemplateModalDocument, options);
        }
export function useTemplateModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TemplateModalQuery, TemplateModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplateModalQuery, TemplateModalQueryVariables>(TemplateModalDocument, options);
        }
export type TemplateModalQueryHookResult = ReturnType<typeof useTemplateModalQuery>;
export type TemplateModalLazyQueryHookResult = ReturnType<typeof useTemplateModalLazyQuery>;
export type TemplateModalSuspenseQueryHookResult = ReturnType<typeof useTemplateModalSuspenseQuery>;
export type TemplateModalQueryResult = Apollo.QueryResult<TemplateModalQuery, TemplateModalQueryVariables>;
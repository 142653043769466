import { StorageLocationEnum } from '__generated__/types';
import { CURRENT_REGION } from 'config/regions/config';
import { Regions } from 'config/regions/types';
import { StorageState, StorageType } from 'interfaces/stores/StorageStore';
import { filter } from 'lodash-es';

export const types = [
  {
    value: StorageType.Inventory,
    label: 'Inventory',
  },
  {
    value: StorageType.Utilization,
    label: 'Utilization',
  },
  {
    value: StorageType.Canceled,
    label: 'Canceled',
  },
];

export const inventoryStates = [
  {
    value: StorageState.NotProcessed,
    label: 'Not yet processed',
    final: false,
  },
  {
    value: StorageState.InProcessing,
    label: 'In processing (on QC)',
    final: false,
  },
  {
    value: StorageState.PartiallyProcessed,
    label: 'Partially processed (manual)',
    final: false,
  },
  {
    value: StorageState.ProcessedForRepack,
    label: 'Processed for Repack (X2C)',
    final: true,
  },
  {
    value: StorageState.ProcessedForReturn,
    label: 'Processed for Return (X2P)',
    final: true,
  },
  {
    value: StorageState.ProcessedForStorage,
    label: 'Processed for Inventory / Util',
    final: true,
  },
  {
    value: StorageState.ProcessedAndSplit,
    label: 'Processed & split',
    final: true,
  },
];

export const utilizationStates = [
  {
    value: StorageState.NotProcessed,
    label: 'Not yet processed',
    final: false,
  },
  {
    value: StorageState.TestPart,
    label: 'Test Part',
    final: false,
  },
  {
    value: StorageState.ReproductionPlanned,
    label: 'Reproduction planned',
    final: false,
  },
  {
    value: StorageState.Cancellation,
    label: 'Cancellation',
    final: false,
  },
];

export const storageStateByType: Record<StorageType, typeof inventoryStates> = {
  [StorageType.Inventory]: inventoryStates,
  [StorageType.Utilization]: utilizationStates,
  [StorageType.Canceled]: [],
};

export const filteredUtilizationStates = (testDeal: boolean) => {
  if (testDeal) return utilizationStates;

  return filter(utilizationStates, (state) => state.value !== StorageState.TestPart);
};

export const locationLabels: Record<StorageLocationEnum, string> = {
  [StorageLocationEnum.ShippingRoom]: 'Shipping Room',
  [StorageLocationEnum.QcRoom]: 'QC Room',
  [StorageLocationEnum.StorageRoom]: 'Storage Room',
  [StorageLocationEnum.BigRoom]: 'Big Room',
  [StorageLocationEnum.Basement]: 'Basement',
  [StorageLocationEnum.Otto2Warehouse]: 'Warehouse 2 Ottobrunn',
  [StorageLocationEnum.GlonnWarehouse]: 'Glonn Warehouse',
  [StorageLocationEnum.DbSchenkerWarehouse]: 'Schenker warehouse',
  [StorageLocationEnum.Trashed]: 'Trashed',
  [StorageLocationEnum.DisposeOf]: 'Dispose of',
};

const toOption = (value: StorageLocationEnum) => ({
  value,
  label: locationLabels[value],
});

const inventoryLocationsByRegion = {
  [Regions.EU]: [
    StorageLocationEnum.StorageRoom,
    StorageLocationEnum.QcRoom,
    StorageLocationEnum.GlonnWarehouse,
    StorageLocationEnum.DbSchenkerWarehouse,
    StorageLocationEnum.Otto2Warehouse,
    StorageLocationEnum.BigRoom,
    StorageLocationEnum.Basement,
  ],
  [Regions.TR]: [StorageLocationEnum.StorageRoom, StorageLocationEnum.ShippingRoom, StorageLocationEnum.QcRoom],
  [Regions.UK]: [StorageLocationEnum.StorageRoom, StorageLocationEnum.DisposeOf],
};

const currentRegionInventoryLocations = inventoryLocationsByRegion[CURRENT_REGION];

export const locationOptions = currentRegionInventoryLocations.map(toOption);

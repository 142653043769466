import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { MoneyFragmentDoc } from './money';
export type PerformanceFragment = { __typename?: 'Performance', totalOrdersCount?: string | null, totalOrdersValue?: string | null, ordersInProductionCount?: string | null, ordersInProductionValue?: string | null, ordersBePaidCount?: string | null, ordersBePaidValue?: string | null, ordersPaidCount?: string | null, ordersPaidValue?: string | null, ordersInProductionMoney?: { __typename?: 'Money', amount: string, amountCents: string, currencyCode: Types.CurrencyEnum } | null };

export const PerformanceFragmentDoc = gql`
    fragment performance on Performance {
  totalOrdersCount
  totalOrdersValue
  ordersInProductionCount
  ordersInProductionValue
  ordersInProductionMoney {
    ...money
  }
  ordersBePaidCount
  ordersBePaidValue
  ordersPaidCount
  ordersPaidValue
}
    ${MoneyFragmentDoc}`;
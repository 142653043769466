/* eslint-disable no-restricted-imports */
// TODO: Make typings refactoring.
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Money,
  OmsInputsShippingsCreateType,
  ShippingCustomsClearanceStatusEnum,
  ShippingCustomsDocumentsStatusEnum,
  ShippingDeliveryBasisEnum,
  ShippingDeliveryTypeEnum,
  ShippingDirectionEnum,
  ShippingPositionNamingEnum,
  WarehouseInstructionEnum,
} from '__generated__/types';
import { updateHsCodesForPart } from 'api/updateHsCodesForPart';
import { isEuropean } from 'collections/countries';
import { processingStatuses, ShippingDirection, ShippingProcessingStatus } from 'collections/shippingConstants';
import { defaultXometryAddress, xometryAddressPoland } from 'collections/xometryAddress';
import { HsCodeFormValues } from 'components/Deal/shared/PartHsCodeForm/PartHsCodeForm.types';
import { addDataToAddress, notifyAboutAddressUpdate } from 'components/Deal/Shipping/shared/Address/addDataToAddress';
import { Notification } from 'components/UI';
import { Part } from 'interfaces';
import { ICurrentlyViewing } from 'interfaces/shared';
import {
  CustomsDocumentsStatus,
  IAdditionalService,
  IAddress,
  IDealLot,
  IDealPart,
  IRelatedDocument,
  IRepackForCustomerParams,
  IRepackForPartnerReturnParams,
  IServerDealLot,
  IShipping,
  IShippingDimensions,
  IShippingLot,
  IStorageParams,
  ITrackingRecord,
  IUser,
  ProvideOrderSource,
  ProviderOrderOption,
  ShippingSource,
} from 'interfaces/stores/Shipping';
// eslint-disable-next-line no-restricted-imports
import { find, findIndex, get, isEqual, map, max, omit } from 'lodash-es';
import { action, computed, observable, toJS } from 'mobx';
import moment, { Moment } from 'moment';
import { routes } from 'routes';
import { routesApi } from 'routes/api';
import { RootStore } from 'stores/RootStore';
import { ModalStore } from 'stores/shared/ModalStore';
import uniqid from 'uniqid';
import { convertOcToShAddress } from 'utils/address';
import api, { camelizeKeys, decamelizeKeys } from 'utils/axios';
import { isExport } from 'utils/deal/shipping/direction';
import { PersonDealQuery } from 'utils/graphql/queries/__generated__/personDeal';
import { openFile } from 'utils/hooks/useFileOpener';
import { toIMoney, XMoney } from 'utils/money';

const fieldsToOmitOnUpdate = [
  'allowDirectShipping',
  'autoOrderState',
  'attachedFiles',
  'currentlyViewing',
  'customsClearanceStatusUpdatedAt',
  'dealId',
  'holdBy',
  'id',
  'internalComment',
  'isExport',
  'isImport',
  'isImportAndExport',
  'jumingoOrderState',
  'masterShippingId',
  'netto',
  'noInvoiceRequiredAt',
  'noInvoiceRequiredBy',
  'notifiedCustomerAt',
  'notifiedPartnerAt',
  'number',
  'owner',
  'packagingReportCheckedAt',
  'packagingReportCreatedBy',
  'readyForCollectionAt',
  'readyForCollectionBy',
  'receivedProcessingAt',
  'receivedProcessingBy',
  'referringDocuments',
  'relatedDocuments',
  'relatedProviderOrders',
  'shippingAlertAt',
  'shippingAlertBy',
  'shippingAlertMessage',
  'tara',
  'trackingCheckedAt',
  'trackingLink',
  'trackingRecords',
  'directionFromEndProvider',
  'autoOrderState',
  'shippingCostCurrency',
  'createdAt',
  'updatedAt',
];

const addressOmitFields = ['personEmail', 'companyName'];

export class ShippingStore {
  public rootStore: RootStore;

  @observable addressSourceDocuments: ProvideOrderSource[] = [];
  @observable commercialInvoiceHtml = '';
  @observable dealParts: Map<number, IDealPart> = new Map<number, IDealPart>();
  @observable dealId = 0;
  @observable filesRemoving: number[] = [] as number[];
  @observable inventoryNoteHtml = '';
  @observable isFetching = true;
  @observable isFileUpload = false;
  @observable lotsAreFetching = false;
  @observable lotSourceDocument?: ShippingSource;
  @observable lotSourceDocuments: ShippingSource[] = [];
  @observable referenceTagHtml = '';
  @observable shipping: IShipping = {} as IShipping;
  @observable initialShipping: IShipping = {} as IShipping;
  @observable tempDealLots?: IServerDealLot[];
  @observable ignoreInstructions = false;

  personData: PersonDealQuery['person'];

  originalShipping: IShipping = {} as IShipping;

  addToMasterShippingModal = new ModalStore();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.reset();
  }

  // ***********************
  //   Computed properties
  // ***********************

  @computed get sourceName() {
    if (this.dealId && this.shipping.sourceProviderOrderId) {
      return `PO-${this.dealId}-${this.shipping.sourceProviderOrderId}`;
    }
  }

  @computed get addressSourceDocument() {
    return this.addressSourceDocuments.find((doc) => doc.sourceName === this.sourceName);
  }

  @computed get directionContainsPartner() {
    return this.shipping.direction?.includes('p') ?? false;
  }

  @computed get showWarehouse() {
    return this.shipping.direction === ShippingDirection.P2X || this.shipping.direction === ShippingDirection.C2X;
  }

  @computed get lastLotPosition() {
    return max(map(Array.from(this.dealParts.values()), (part) => part.position));
  }

  @computed get packagingReportFiles() {
    return this.shipping.attachedFiles.filter((f) => f.context === 'shipping_packaging_report');
  }

  @computed get labelFiles() {
    return this.shipping.attachedFiles.filter((f) => f.context === 'shipping_label');
  }

  @computed get waybillFiles() {
    return this.shipping.attachedFiles.filter((f) => f.context === 'shipping_waybill');
  }

  @computed get hasUnsavedChanges() {
    return !isEqual(toJS(this.shipping), this.originalShipping);
  }

  @computed get holdByName() {
    if (this.shipping.holdById) {
      return this.rootStore.userStore.name;
    }

    if (this.shipping.holdBy?.name) {
      return this.shipping.holdBy.name;
    }

    return null;
  }

  @computed get holdAt() {
    return this.shipping.holdAt ? moment(this.shipping.holdAt).format('DD.MM.YYYY HH:mm') : null;
  }

  @computed get holdUntil() {
    return this.shipping.holdAt ? moment(this.shipping.holdUntil) : null;
  }

  @computed get noInvoiceRequiredBy() {
    if (this.shipping.noInvoiceRequired) {
      const by = this.shipping.noInvoiceRequiredBy;

      const at = this.shipping.noInvoiceRequiredAt
        ? moment(this.shipping.noInvoiceRequiredAt).format('DD.MM.YYYY HH:mm')
        : '';

      if (by) {
        return `by ${by.name} ${at ? `at ${at}` : ''}`;
      }
    }

    return null;
  }

  @computed get masterShippingId() {
    if (this.shipping.masterShippingId === null || this.shipping.masterShippingId === undefined) {
      return null;
    }

    return String(this.shipping.masterShippingId);
  }

  // Filter provider orders based on shipping direction
  @computed get providerOrders(): ProviderOrderOption[] {
    if (this.ignoreInstructions || this.shipping.direction !== ShippingDirection.P2C) {
      return this.addressSourceDocuments;
    }

    return this.addressSourceDocuments.map((doc) => ({
      ...doc,
      disabled: doc.shipDirection !== this.shipping.direction,
    }));
  }

  @computed get showEndCustomer() {
    const isProdIntercompanyRole = this.rootStore.orderConfirmationStore.isProdIntercompanyRole;
    const direction = this.shipping.direction;
    const toCustomer = direction === ShippingDirection.P2C || direction === ShippingDirection.X2C;

    return toCustomer && isProdIntercompanyRole;
  }

  @computed get endCustomerShowedAndTrue() {
    return this.showEndCustomer && this.shipping.directionToEndCustomer;
  }

  @computed get isEndCustomerDisabled() {
    const shipToEndCustomerPO = this.addressSourceDocument?.shipDirectionToEndCustomer;
    const ignoreInstructions = this.ignoreInstructions;

    return !(shipToEndCustomerPO || ignoreInstructions);
  }

  @computed get showEndProvider() {
    const direction = this.shipping.direction;

    if (direction?.startsWith('p') && this.shipping.directionFromEndProvider) {
      return true;
    }
  }

  @computed get shippingParameters() {
    const shippingParameters = this.shipping.shippingParameters || [];
    const count = shippingParameters.length;

    return count > 0
      ? shippingParameters
      : [{ brutto: undefined, length: undefined, width: undefined, height: undefined }];
  }

  // ***********************
  //   Public AJAX actions
  // ***********************

  @action setNoInvoiceRequired = (value: boolean) => {
    this.shipping.noInvoiceRequired = value;
    this.shipping.noInvoiceRequiredAt = null;
    this.shipping.noInvoiceRequiredBy = {
      id: Number(this.rootStore.userStore.id),
      name: this.rootStore.userStore.name || '',
    };
  };

  @action setNoInvoiceRequiredComment = (comment: string) => {
    this.shipping.noInvoiceRequiredComment = comment;
  };

  @action cancel = async () => {
    this.setIsFetching(true);

    try {
      const response = await api.put(routesApi.dealShippingCancelPath(this.dealId, this.shipping.id));

      const payload = get(response, 'data.payload');
      this.assignAttributes(payload);
    } finally {
      this.setIsFetching(false);
    }
  };

  @action clone = async () => {
    this.setIsFetching(true);

    try {
      const response = await api.post(routesApi.dealShippingClonePath(this.dealId, this.shipping.id));

      const id = get(response, 'data.id');
      window.location.assign(routes.editShippingPath(this.dealId, id));
    } catch (e) {
      this.setIsFetching(false);
      console.error(e);
    }
  };

  @action createReturnToXometry = async () => {
    this.setIsFetching(true);

    try {
      const response = await api.put(routesApi.dealShippingCreateReturnToXometryPath(this.dealId, this.shipping.id));

      const shipping = get(response, 'data.shipping');
      this.assignAttributes(shipping);

      const returnId = get(response, 'data.return_id');
      const href = routes.editShippingPath(this.dealId, returnId);
      window.open(href);
    } finally {
      this.setIsFetching(false);
    }
  };

  @action fetch = async (): Promise<void> => {
    this.setIsFetching(true);

    try {
      const response = await api.get(routesApi.dealShippingPath(this.dealId, this.shipping.id));

      const payload = get(response, 'data.payload');
      this.assignAttributes(payload);
    } finally {
      this.setIsFetching(false);
    }
  };

  @action fetchAddressSourceDocuments = async (): Promise<void> => {
    this.setIsFetching(true);

    try {
      const response = await api.get(routesApi.shippingsSourcesPath(this.dealId), {
        params: {
          objects: 'PO',
        },
      });

      const payload = get(response, 'data.payload');
      this.setAddressSourceDocuments(payload);
    } finally {
      this.setIsFetching(false);
    }
  };

  @action fetchLotSourceDocuments = async (): Promise<void> => {
    this.setIsFetching(true);

    try {
      const response = await api.get(routesApi.shippingsSourcesPath(this.dealId));

      const payload = get(response, 'data.payload');
      this.setLotSourceDocuments(payload);
    } finally {
      this.setIsFetching(false);
    }
  };

  @action fetchLots = async (): Promise<void> => {
    if (this.lotSourceDocument == null) return;

    this.setLotsAreFetching(true);

    try {
      const url = routesApi.shippingsSourcePositionsPath(
        this.dealId,
        this.lotSourceDocument.sourceType,
        this.lotSourceDocument.sourceId,
      );
      const response = await api.get(url);

      const payload = get(response, 'data.payload');
      this.setTempLots(payload);
    } finally {
      this.setLotsAreFetching(false);
    }
  };

  @action receiveForQC = async (): Promise<void> => {
    this.setIsFetching(true);

    try {
      const response = await api.put(routesApi.dealShippingReceiveForQCPath(this.dealId, this.shipping.id));

      const shipping = get(response, 'data.shipping');
      this.assignAttributes(shipping);

      void openFile(routesApi.shippingDownloadInventoryNotePath(this.shipping.id), { print: true });
    } finally {
      this.setIsFetching(false);
    }
  };

  @action receiveForStorage = async (params: IStorageParams): Promise<void> => {
    this.setIsFetching(true);

    try {
      const decamelizedParams = decamelizeKeys(params);

      const response = await api.put(
        routesApi.dealShippingReceiveForStoragePath(this.dealId, this.shipping.id),
        decamelizedParams,
      );

      const shipping = get(response, 'data.shipping');
      this.assignAttributes(shipping);

      const storageId = get(response, 'data.storage_id');
      const href = routes.editStoragePath(this.dealId, storageId);
      this.rootStore.history.push(href);

      const inventoryNoteHref = routes.storageInventoryNotePath(storageId);
      window.open(inventoryNoteHref);
    } finally {
      this.setIsFetching(false);
    }
  };

  @action removeAttachment = async (file: { id: number }) => {
    this.addRemovingFile(file.id);

    try {
      const response = await api.delete(routesApi.entityAttachmentsDestroyPath('shippings', this.shipping.id), {
        params: {
          attached_file_id: file.id,
        },
      });

      if (![200, 204].includes(response.status)) throw new Error();

      this.removeAttachedFile(file);
    } finally {
      this.deleteRemovingFile(Number(file.id));
    }
  };

  @action repackForCustomer = async (params: IRepackForCustomerParams): Promise<void> => {
    this.setIsFetching(true);

    try {
      const decamelizedParams = decamelizeKeys(params);
      const response = await api.put(
        routesApi.dealShippingRepackForCustomerPath(this.dealId, this.shipping.id),
        decamelizedParams,
      );

      const shipping = get(response, 'data.shipping');
      this.assignAttributes(shipping);

      const repackedId = get(response, 'data.repacked_id');

      const href = routes.editShippingPath(this.dealId, repackedId);
      this.rootStore.history.push(href);

      void openFile(routesApi.shippingDownloadInventoryNotePath(repackedId), { print: true });
    } finally {
      this.setIsFetching(false);
    }
  };

  @action repackForPartnerReturn = async (params: IRepackForPartnerReturnParams): Promise<void> => {
    this.setIsFetching(true);

    try {
      const decamelizedParams = decamelizeKeys(params);
      const response = await api.put(
        routesApi.dealShippingRepackForPartnerReturnPath(this.dealId, this.shipping.id),
        decamelizedParams,
      );

      const shipping = get(response, 'data.shipping');
      this.assignAttributes(shipping);

      const repackedId = get(response, 'data.repacked_id');

      const href = routes.editShippingPath(this.dealId, repackedId);
      this.rootStore.history.push(href);

      void openFile(routesApi.shippingDownloadInventoryNotePath(repackedId), { print: true });
    } finally {
      this.setIsFetching(false);
    }
  };

  @action bulkConfirmHSCodes = async (lots: IShippingLot[]): Promise<void> => {
    return api
      .patch(routesApi.dealPartsBulkUpdatePath(this.rootStore.dealStore.data.id), {
        parts: {
          hs_code_state: 'confirmed',
          ids: lots.map((lot) => lot.partId),
        },
      })
      .then(() => this.fetch())
      .then(() => {
        Notification.Success('Successfully updated Parts!');
      })
      .catch(() => {
        Notification.Error('Failed to update Parts!');
      });
  };

  @action uploadFile = async (file?: File, context = 'default'): Promise<void> => {
    if (file == null) return;

    this.setIsFileUpload(true);

    try {
      const fd = new FormData();
      fd.append('file', file);
      fd.append('context', context);

      const response = await api.post(
        routesApi.dealShippingUploadAttachedFilesPath(this.dealId, this.shipping.id),
        fd,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );

      if (response.status !== 200) throw new Error();

      const { payload } = response.data;

      this.assignAttachedFiles(payload.attached_file);
    } finally {
      this.setIsFileUpload(false);
    }
  };

  @action uploadPackagingReport = async (file?: File): Promise<void> => {
    await this.uploadFile(file, 'shipping_packaging_report');
  };

  @action uploadShippingLabel = async (file?: File): Promise<void> => {
    await this.uploadFile(file, 'shipping_label');
  };

  // *******************
  //   Public actions
  // *******************

  @action addService = (service: IAdditionalService) => {
    this.shipping.services.push(service);
  };

  @action addTrackingRecord = (record: ITrackingRecord) => {
    this.shipping.trackingRecords = [record, ...this.shipping.trackingRecords];
  };

  @action openQC = (qcId: number) => {
    const href = routes.editDealQualityControlPath(this.dealId, qcId);
    window.open(href);

    void openFile(routesApi.shippingDownloadInventoryNotePath(this.shipping.id), { print: true });
  };

  @action removePosition = ({ id, partId }: IShippingLot): void => {
    this.shipping.lots = this.shipping.lots.filter((p) => p.id !== id);

    if (this.shipping.lots.filter((p) => p.partId === partId).length < 0) {
      this.dealParts.delete(partId);
    }
  };

  @action removeService = (service: IAdditionalService): void => {
    this.shipping.services = this.shipping.services.filter((s) => s._key !== service._key);
  };

  @action reset = () => {
    this.addressSourceDocuments = [];
    this.dealParts = new Map<number, IDealPart>();
    this.lotSourceDocument = undefined;
    this.lotSourceDocuments = [];
    this.shipping = {} as IShipping;
    this.shipping.services = [];
    this.shipping.lots = [];
    this.originalShipping = toJS(this.shipping);
    this.ignoreInstructions = false;
  };

  @action resetTempLots = () => {
    this.tempDealLots = undefined;
  };

  @action setAddressSourceDocument = (sourceId?: number) => {
    this.shipping.sourceProviderOrderId = sourceId || null;

    if (!sourceId) {
      this.shipping.warehouseInstruction = null;

      return;
    }

    const matchedProviderOrder = find(this.addressSourceDocuments, (s) => s.sourceId === sourceId);

    if (!matchedProviderOrder) return;

    // Update store values if direction includes Provider
    if (matchedProviderOrder?.shipDirection) {
      const direction = this.shipping.direction;
      const address = matchedProviderOrder.address;

      if (direction?.startsWith('p')) {
        this.setSourceAddress(address);
      }

      if (direction?.endsWith('p')) {
        this.setDestinationAddress(address);
      }
    }

    // Set values from selected provider order
    const shipWarehouse = matchedProviderOrder?.shipWarehouse;
    const shipDirectionToEndCustomer = matchedProviderOrder?.shipDirectionToEndCustomer;
    const deliveryBasis = matchedProviderOrder?.shipIncoterms;

    if (shipWarehouse) {
      if (shipWarehouse === WarehouseInstructionEnum.NotApplicable) {
        this.shipping.warehouseInstruction = null;
      } else {
        this.shipping.warehouseInstruction = shipWarehouse;
      }
    }

    if (shipDirectionToEndCustomer !== undefined) {
      if (shipDirectionToEndCustomer) {
        this.enableEndCustomer();
      } else {
        this.disableEndCustomer();
      }
    }

    if (deliveryBasis) {
      this.shipping.deliveryBasis = deliveryBasis;
    }
  };

  @action setPersonData = (personData: PersonDealQuery['person']) => {
    this.personData = personData;
  };

  @action setActualDeliveryDate = (value?: string) => {
    this.shipping.actualDeliveryDate = value && moment(value, 'DD.MM.YYYY').format('YYYY-MM-DD');
  };

  @action setActualShipmentDate = (value?: string) => {
    this.shipping.actualShipmentDate = value && moment(value, 'DD.MM.YYYY').format('YYYY-MM-DD');
  };

  @action setCurrentlyViewing = (value: ICurrentlyViewing[]): void => {
    this.shipping.currentlyViewing = value;
    this.originalShipping.currentlyViewing = value;
  };

  @action setCustomsDocumentsStatus = (value: CustomsDocumentsStatus): void => {
    this.shipping.customsDocumentsStatus = value;
  };

  @action setCustomsClearanceStatus = (value: ShippingCustomsClearanceStatusEnum): void => {
    if (this.originalShipping.customsClearanceStatus === value) {
      this.shipping.customsClearanceStatus = value;
      this.shipping.customsClearanceStatusUpdatedAt = this.originalShipping.customsClearanceStatusUpdatedAt;

      return;
    }

    this.shipping.customsClearanceStatus = value;
    this.shipping.customsClearanceStatusUpdatedAt = null;
  };

  @action setCustomsOfficeCode = (value: string): void => {
    this.shipping.customsOfficeCode = value;
  };

  @action setDealId = (dealId: number | string): void => {
    this.dealId = Number(dealId);
  };

  @action setProviderOrderId = (providerOrder: number): void => {
    this.setAddressSourceDocument(providerOrder);
  };

  @action setDeliveryBasis = (value: ShippingDeliveryBasisEnum): void => {
    this.shipping.deliveryBasis = value;
  };

  @action setDeliveryNoteComment = (value: string): void => {
    this.shipping.deliveryNoteComment = value;
  };

  @action setHoldBy = (value: Moment | null): void => {
    if (value) {
      this.shipping.holdUntil = moment(value).format('YYYY-MM-DD');
      this.shipping.holdAt = moment().toISOString();
      this.shipping.holdById = this.rootStore.userStore.id;
    } else {
      this.shipping.holdUntil = null;
      this.shipping.holdAt = null;
      this.shipping.holdById = null;
      this.shipping.holdBy = undefined;
    }
  };

  @action setDeliveryType = (value: ShippingDeliveryTypeEnum): void => {
    this.shipping.deliveryType = value;
  };

  @action setDestinationAddress = (address: IAddress | undefined): void => {
    this.shipping.destinationAddress = address;
    this.recalculateCustomsDocumentsStatus();
  };

  @action setDimensions = (dimensions: IShippingDimensions): void => {
    this.shipping = {
      ...this.shipping,
      ...dimensions,
    };
  };

  @action setDirection = (direction: ShippingDirection): void => {
    const dealStore = this.rootStore.dealStore;
    const providerAddress = this.addressSourceDocument?.address;
    const personData = this.personData;
    const customerAddress = this.rootStore.orderConfirmationStore.customerAddress;
    const isTestDeal = dealStore.data.testDeal;
    const isEUAddress = providerAddress?.country && isEuropean(providerAddress.country);
    const isP2X = direction === ShippingDirection.P2X;

    this.shipping.direction = direction;
    this.shipping.directionToEndCustomer = false;

    const { newAddress: customerAddressWithContactData, addedData } = addDataToAddress(personData, customerAddress);

    const xometryAddress = isTestDeal && isEUAddress && isP2X ? xometryAddressPoland : defaultXometryAddress;

    const [source, destination] = ((direction: ShippingDirection) => {
      switch (direction) {
        case ShippingDirection.C2P:
          notifyAboutAddressUpdate(addedData, 'Customer');

          return [customerAddressWithContactData, providerAddress];
        case ShippingDirection.C2X:
          notifyAboutAddressUpdate(addedData, 'Customer');

          return [customerAddressWithContactData, xometryAddress];
        case ShippingDirection.P2C:
          if (!this.endCustomerShowedAndTrue) {
            notifyAboutAddressUpdate(addedData, 'Customer');
          }

          return [providerAddress, customerAddressWithContactData];
        case ShippingDirection.P2P:
          return [providerAddress, providerAddress];
        case ShippingDirection.P2X:
          return [providerAddress, xometryAddress];
        case ShippingDirection.X2C:
          if (!this.endCustomerShowedAndTrue) {
            notifyAboutAddressUpdate(addedData, 'Customer');
          }

          return [xometryAddress, customerAddressWithContactData];
        case ShippingDirection.X2P:
          return [xometryAddress, providerAddress];
        default:
          return [undefined, undefined];
      }
    })(direction);

    this.setSourceAddress(source);
    this.setDestinationAddress(destination);

    if (this.endCustomerShowedAndTrue) {
      this.enableEndCustomer();
    }

    if (!direction.includes('c')) {
      this.setPublishedInCa(false);
      this.setNotifyCustomer(false);
    }

    if (!direction.includes('p')) {
      this.setPublishedInPa(false);
      this.setNotifyPartner(false);
    }

    const matchingStatus = find(
      processingStatuses,
      (x) => x.directions.includes(direction) && x.value === this.shipping.receivedProcessingStatus,
    )?.value;

    if (!matchingStatus) {
      this.setReceivedProcessingStatus(ShippingProcessingStatus.NotProcessed);
    }
  };

  @action setWarehouseInstruction = (warehouseInstruction: WarehouseInstructionEnum) => {
    this.shipping.warehouseInstruction = warehouseInstruction;
  };

  @action setExpectedDeliveryDate = (value?: string): void => {
    this.shipping.expectedDeliveryDate = value && moment(value, 'DD.MM.YYYY').format('YYYY-MM-DD');
  };

  @action setExpectedShipmentDate = (value?: string): void => {
    this.shipping.expectedShipmentDate = value && moment(value, 'DD.MM.YYYY').format('YYYY-MM-DD');
  };

  @action setInternalComment = (value: string): void => {
    this.shipping.internalComment = value;
  };

  @action setIsPartial = (value: boolean): void => {
    this.shipping.isPartial = value;
  };

  @action setShippingParameter = (
    index: number,
    key: 'length' | 'height' | 'width' | 'brutto',
    value: number | undefined,
  ): void => {
    if (!this.shipping.shippingParameters) {
      this.shipping.shippingParameters = [];
    }

    if (!this.shipping.shippingParameters[index]) {
      this.shipping.shippingParameters[index] = {
        length: undefined,
        width: undefined,
        height: undefined,
        brutto: undefined,
      };
    }

    this.shipping.shippingParameters[index][key] = value;
  };

  @action addShippingParameter = (): void => {
    this.shipping.shippingParameters?.push({
      length: undefined,
      width: undefined,
      height: undefined,
      brutto: undefined,
    });
  };

  @action duplicateShippingParameter = (index: number): void => {
    const shippingParameter = this.shipping.shippingParameters?.[index];

    if (shippingParameter) {
      this.shipping.shippingParameters?.push({ ...shippingParameter });
    } else {
      Notification.Error('Cannot duplicate shipping parameter', `Index ${index} not found`);
    }
  };

  @action deleteShippingParameter = (index: number): void => {
    this.shipping.shippingParameters?.splice(index, 1);
  };

  @action setLogisticAggregator = (value: string): void => {
    this.shipping.logisticAggregator = value;
  };

  @action setLogisticOperator = (value: string): void => {
    this.shipping.logisticOperator = value;
  };

  @action addNewLots = (lots: IServerDealLot[]) => {
    const newLots = this.transformLots(lots).map((lot) => ({ ...lot, id: null, parentId: lot.id }));
    this.shipping.lots = [...this.shipping.lots, ...newLots];

    this.assignDealParts(lots);
  };

  @action setLots = (lots: IDealLot[]) => {
    this.shipping.lots = [...this.shipping.lots, ...this.transformLots(lots)];

    this.assignDealParts(lots);
  };

  @action setLotSourceDocument = (name: string): void => {
    this.lotSourceDocument = find(this.lotSourceDocuments, (s) => s.sourceName === name);
  };

  @action setMovementReferenceNumber = (value: string): void => {
    this.shipping.movementReferenceNumber = value;
  };

  @action setShippingOption = (value: string): void => {
    this.shipping.shippingOption = value;
  };

  @action setNotifyCustomer = (value: boolean): void => {
    this.shipping.notifyCustomer = value;
  };

  @action setNotifyPartner = (value: boolean): void => {
    this.shipping.notifyPartner = value;
  };

  @action setOwner = (owner?: IUser): void => {
    this.shipping.owner = owner;
  };

  @action setPackagingReportCheckStatus = (status: string): void => {
    this.shipping.packagingReportCheckStatus = status;
  };

  @action setPositionNaming = (value: ShippingPositionNamingEnum): void => {
    this.shipping.positionNaming = value;
  };

  @action setPublishedInCa = (value: boolean): void => {
    this.shipping.publishedInCa = value;
  };

  @action setPublishedInPa = (value: boolean): void => {
    this.shipping.publishedInPa = value;
  };

  @action setReceivedProcessingStatus = (value: string): void => {
    this.shipping.receivedProcessingStatus = value;
  };

  @action setReadyForCollection = (value: boolean): void => {
    this.shipping.readyForCollection = value;
  };

  @action setShippingAlert = (value: boolean): void => {
    this.shipping.shippingAlert = value;
  };

  @action setShippingCost = (value: number | null | undefined): void => {
    this.shipping.shippingCost = value || undefined;
  };

  @action setShippingOrderNumber = (value: string): void => {
    this.shipping.shippingOrderNumber = value;
  };

  @action setShippingId = (shippingId: number | string): void => {
    this.shipping.id = Number(shippingId);
    this.originalShipping.id = Number(shippingId);
  };

  @action setSourceAddress = (address: IAddress | undefined): void => {
    this.shipping.sourceAddress = address;
    this.recalculateCustomsDocumentsStatus();
  };

  @action setTrackingNumber = (value: string): void => {
    this.shipping.trackingNumber = value;
  };

  @action recalculateCustomsDocumentsStatus = (): void => {
    if (isExport(this.shipping) && this.shipping.customsDocumentsStatus === CustomsDocumentsStatus.NotRequired) {
      this.setCustomsDocumentsStatus(CustomsDocumentsStatus.New);
    } else if (
      !isExport(this.shipping) &&
      this.shipping.customsDocumentsStatus !== CustomsDocumentsStatus.NotRequired
    ) {
      this.setCustomsDocumentsStatus(CustomsDocumentsStatus.NotRequired);
    }
  };

  @action updateAdditionalService = (service: IAdditionalService): void => {
    const currency = this.shipping.services[0].valueMoney?.currencyCode || this.shipping.shippingCostCurrency;
    const index = findIndex(this.shipping.services, (s) => s._key === service._key);

    const newService = {
      ...service,
      valueMoney: XMoney(service.value, currency).toIMoney(),
    };

    this.shipping.services = [
      ...this.shipping.services.slice(0, index),
      newService,
      ...this.shipping.services.slice(index + 1),
    ];
  };

  @action updateDealPartPosition(partId: number, position: number) {
    const part = this.dealParts.get(partId);

    if (part) {
      part.position = position;
    }
  }

  @action updatePosition = (position: IShippingLot): void => {
    const index = findIndex(this.shipping.lots, (p) => p.id === position.id);

    const positionWithMoney = {
      ...position,
      customerPriceMoney: XMoney(position.customerPrice, position.customerPriceMoney.currencyCode).toIMoney() as Money,
      providerPriceMoney: XMoney(position.providerPrice, position.providerPriceMoney.currencyCode).toIMoney() as Money,
    };

    this.shipping.lots = [
      ...this.shipping.lots.slice(0, index),
      positionWithMoney,
      ...this.shipping.lots.slice(index + 1),
    ];
  };

  @action updateShipping = (values: any): void => {
    this.shipping = {
      ...this.shipping,
      ...values,
    };
  };

  @action setPartHsCodes = (part: Part) => {
    const lotIndex = findIndex(this.shipping.lots, (p) => p.partId === part.id);
    const lot = this.shipping.lots[lotIndex];

    if (!lot) return;

    const newLot: IShippingLot = {
      ...lot,
      part: {
        ...lot.part,
        hsCode: part.hsCode,
        hsCodeName: part.hsCodeName,
        hsCodePurpose: part.hsCodePurpose,
        hsCodeState: part.hsCodeState,
        hsCodeConfirmedAt: part.hsCodeConfirmedAt,
        hsCodeConfirmedBy: part.hsCodeConfirmedBy,
      },
    };

    this.shipping.lots[lotIndex] = newLot;
  };

  @action updateHsCodeForPart = async (partId: number, values: HsCodeFormValues) => {
    try {
      const response = await updateHsCodesForPart(partId, values);

      this.setPartHsCodes(response);
    } catch (error) {
      Notification.Error('Error updating HS Codes');
      console.error(error);
    }
  };

  @action setMasterShippingId = (value: string | number | null): void => {
    const newMasterShippingId = value == null ? null : Number(value);

    this.shipping.masterShippingId = newMasterShippingId;
    this.originalShipping.masterShippingId = newMasterShippingId;
  };

  @action handleRemoveFromMasterShipping = () => {
    this.setMasterShippingId(null);
  };

  @action handleAddToMasterShipping = (id: string) => {
    this.setMasterShippingId(id);

    Notification.Success(`Shipping ${this.shipping.number} was successfully added to MSH-${id}`);
  };

  @action setIsFetching = (value: boolean): void => {
    this.isFetching = value;
  };

  @action toggleIgnoreInstructions = (value?: boolean) => {
    if (value === undefined) {
      this.ignoreInstructions = !this.ignoreInstructions;
    } else {
      this.ignoreInstructions = value;
    }
  };

  @action toggleEndCustomer = () => {
    if (this.shipping.directionToEndCustomer) {
      this.disableEndCustomer();
    } else {
      this.enableEndCustomer();
    }
  };

  @action enableEndCustomer = () => {
    this.shipping.directionToEndCustomer = true;

    if (!this.showEndCustomer) return;

    const endCustomerAddress = this.rootStore.orderConfirmationStore.orderConfirmation.endCustomerShippingAddress;

    if (endCustomerAddress) {
      this.setDestinationAddress(convertOcToShAddress(endCustomerAddress));
    } else {
      Notification.Error('No end customer address found');
    }
  };

  @action disableEndCustomer = () => {
    this.shipping.directionToEndCustomer = false;

    if (!this.showEndCustomer) return;

    const ocShippingAddress = this.rootStore.orderConfirmationStore.orderConfirmation.shippingAddress;

    if (ocShippingAddress) {
      this.setDestinationAddress(convertOcToShAddress(ocShippingAddress));
    } else {
      Notification.Error('No OC shipping address found');
    }
  };

  // *******************
  //   Private actions
  // *******************

  @action private addRemovingFile = (id: number): void => {
    this.filesRemoving = [...this.filesRemoving, id];
  };

  @action private assignAttachedFiles = (payload: any): void => {
    const camelized = camelizeKeys(payload);

    this.shipping.attachedFiles = [...this.shipping.attachedFiles, camelized];
  };

  @action private assignAttributes = (shipping: any): void => {
    const camelized = this.convertNullsToUndefineds(camelizeKeys(shipping));

    this.shipping = {
      ...camelized,
      lots: this.transformLots(camelized.lots),
      services: this.transformServices(camelized.services),
      relatedDocuments: this.transformRelatedDocuments(camelized.relatedDocuments),
      referringDocuments: this.transformRelatedDocuments(camelized.referringDocuments),
      packagingReportCheckStatus: camelized.packagingReportCheckStatus ?? 'not_checked',
      shippingAlert: camelized.shippingAlertAt != null,
    };

    this.originalShipping = toJS(this.shipping);

    this.assignDealParts(camelized.lots);
  };

  @action private assignDealParts = (lots: IDealLot[]): void => {
    this.dealParts = lots.reduce((mem, { partId, partPosition }) => {
      mem.set(partId, { id: partId, position: partPosition });

      return mem;
    }, new Map<number, IDealPart>());
  };

  @action private deleteRemovingFile = (id: number): void => {
    this.filesRemoving = [...this.filesRemoving.filter((x) => x !== id)];
  };

  @action private removeAttachedFile = (file: { id: number }): void => {
    this.shipping.attachedFiles = [...this.shipping.attachedFiles.filter((x) => x.id !== file.id)];
  };

  @action private setAddressSourceDocuments = (payload: any) => {
    this.addressSourceDocuments = camelizeKeys(payload);
  };

  @action private setIsFileUpload = (value: boolean): void => {
    this.isFileUpload = value;
  };

  @action private setLotsAreFetching = (value: boolean): void => {
    this.lotsAreFetching = value;
  };

  @action private setLotSourceDocuments = (payload: any) => {
    this.lotSourceDocuments = {
      ...camelizeKeys(payload),
    };
  };

  @action private setTempLots = (payload: any) => {
    const lots = map<any, IServerDealLot>(payload, (item) => camelizeKeys(item));
    this.tempDealLots = lots.filter((lot) => find(this.shipping.lots, (p) => p.id === lot.id) == null);
  };

  // *******************
  //   Private methods
  // *******************

  private convertNullsToUndefineds = (obj: any): any =>
    Object.fromEntries(map(Object.entries(obj), ([k, v]) => [k, v ?? undefined]));

  private convertUndefinedsToNulls = (obj: any): any =>
    Object.fromEntries(map(Object.entries(obj), ([k, v]) => [k, v ?? null]));

  @action prepareAttributes = (values: IShipping) => {
    // Remove unnecessary fields.
    const requiredValues = omit(values, fieldsToOmitOnUpdate);

    return this.convertUndefinedsToNulls({
      ...requiredValues,
      actualDeliveryDate: values.actualDeliveryDate || null,
      actualShipmentDate: values.actualShipmentDate || null,
      shippingParameters: values.shippingParameters,
      customsOfficeCode: values.customsOfficeCode || null,
      dealParts: Array.from(this.dealParts.values()),
      destinationAddress: this.convertUndefinedsToNulls(omit(values.destinationAddress, addressOmitFields)),
      expectedDeliveryDate: values.expectedDeliveryDate || null,
      expectedShipmentDate: values.expectedShipmentDate || null,
      holdAt: values.holdAt || null,
      holdUntil: values.holdUntil || null,
      lots: values.lots.map((lot) => ({
        customerPrice: lot.customerPriceMoney,
        id: lot.id,
        parentId: lot.parentId != null ? String(lot.parentId) : null,
        partId: lot.partId != null ? String(lot.partId) : null,
        providerPrice: lot.providerPriceMoney,
        quantity: lot.quantity,
      })),
      ownerId: values.owner?.id,
      packagingReportCheckStatus: requiredValues.packagingReportCheckStatus ?? 'not_checked',
      services: values.services.map((service) => ({
        id: String(service.id),
        name: service.name,
        position: service.position,
        typeName: service.typeName,
        value: service.value,
        vatRate: service.vatRate,
      })),
      shippingCost: XMoney(values.shippingCost ?? 0).toIMoney(),
      sourceAddress: this.convertUndefinedsToNulls(omit(values.sourceAddress, addressOmitFields)),

      sourceProviderOrderId: values.sourceProviderOrderId || null,
      deliveryBasis: values.deliveryBasis || null,
      directionToEndCustomer: values.directionToEndCustomer || false,
      warehouseInstruction: this.showWarehouse ? values.warehouseInstruction || null : null,
    });
  };

  @action prepareCreateAttributes = (values: IShipping): OmsInputsShippingsCreateType => {
    return {
      sourceProviderOrderId: values.sourceProviderOrderId || null,
      deliveryBasis: values.deliveryBasis || null,
      directionToEndCustomer: values.directionToEndCustomer || false,
      warehouseInstruction: this.showWarehouse ? values.warehouseInstruction || null : null,

      direction: (values.direction as unknown as ShippingDirectionEnum) || null,
      customsDocumentsStatus: (values.customsDocumentsStatus as unknown as ShippingCustomsDocumentsStatusEnum) || null,
      deliveryNoteComment: values.deliveryNoteComment || null,
      holdById: values.holdById || null,
      isPartial: values.isPartial || false,
      actualDeliveryDate: values.actualDeliveryDate || null,
      actualShipmentDate: values.actualShipmentDate || null,
      dealParts: Array.from(this.dealParts.values()).map((part) => ({
        id: String(part.id),
        position: part.position,
      })),
      destinationAddress: this.convertUndefinedsToNulls(omit(values.destinationAddress, addressOmitFields)),
      expectedDeliveryDate: values.expectedDeliveryDate || null,
      expectedShipmentDate: values.expectedShipmentDate || null,
      holdAt: values.holdAt || null,
      holdUntil: values.holdUntil || null,
      lots: values.lots.map((lot) => ({
        customerPrice: lot.customerPriceMoney,
        id: lot.id != null ? String(lot.id) : null,
        parentId: lot.parentId != null ? String(lot.parentId) : null,
        partId: lot.partId != null ? String(lot.partId) : null,
        providerPrice: lot.providerPriceMoney,
        quantity: lot.quantity,
      })),
      ownerId: values.owner?.id ? String(values.owner.id) : null,
      packagingReportCheckStatus: values.packagingReportCheckStatus ?? 'not_checked',
      services: values.services.map((service) => ({
        id: String(service.id),
        name: service.name,
        position: service.position,
        typeName: service.typeName,
        value: service.value,
        vatRate: service.vatRate,
      })),
      shippingCost: toIMoney(XMoney(values.shippingCost ?? 0)),
      sourceAddress: this.convertUndefinedsToNulls(omit(values.sourceAddress, addressOmitFields)),
    };
  };

  private transformLots = (original: IDealLot[]) =>
    map<IDealLot, IShippingLot>(
      original,
      (lot: IDealLot): IShippingLot =>
        ({
          ...lot,
          customerPrice: Number(lot.customerPrice),
          providerPrice: Number(lot.providerPrice),
          part: {
            id: lot.partId,
            name: lot.partName,
            material: lot.partMaterial,
            postProcessing: lot.partPostProcessing,
            customerNote: lot.partCustomerNote,
            isExpress: lot.partIsExpress,
            isPreQuote: lot.partIsPreQuote,
            isSamplesNeeded: lot.partIsSamplesNeeded,
            mpNeeded: lot.partMpNeeded,
            productionRemark: lot.productionRemark,
            internalComment: lot.partInternalComment,
            preQuotedBy: {
              label: String(lot.partPreQuotedByLabel),
            },
            preQuotedComment: lot.partPreQuotedComment,
            samplesQuantity: lot.partSamplesQuantity,
            samplesComment: lot.partSamplesComment,
            hsCode: lot.partHsCode,
            hsCodeName: lot.partHsCodeName,
            hsCodePurpose: lot.partHsCodePurpose,
            hsCodeState: lot.partHsCodeState,
            hsCodeConfirmedBy: lot.partHsCodeConfirmedBy,
            hsCodeConfirmedAt: lot.partHsCodeConfirmedAt,
          },
          quantity: Number(lot.quantity),
          vatRate: Number(lot.vatRate),
          weightKgPerPiece: Number(lot.weightKgPerPiece),
          key: Math.random().toString(36).slice(2),
        }) as IShippingLot,
    );

  private transformRelatedDocuments = (original: any): IRelatedDocument[] => {
    const mapLink = (id: number, type: string) => {
      const dealId = this.dealId.toString();

      switch (type) {
        case 'Deal::ProviderOrder':
          return routes.editProviderOrderPath(dealId, id);
        case 'Deal::Delivery':
          return routes.deliveryPath(dealId, id);
        case 'Deal::QualityControl':
          return routes.editDealQualityControlPath(dealId, id);
        case 'Deal::Shipping':
          return routes.editShippingPath(dealId, id);
        case 'Deal::Storage':
          return routes.editStoragePath(dealId, id);
        default:
          return '';
      }
    };

    return map(original, ({ objectId, objectType, name, canceled }) => ({
      label: name,
      link: mapLink(objectId, objectType),
      canceled,
    }));
  };

  private transformServices = (original: IAdditionalService[]) =>
    map<IAdditionalService, IAdditionalService>(
      original,
      (service: IAdditionalService) =>
        ({
          ...service,
          value: Number(service.value),
          vatRate: Number(service.vatRate),
          _key: uniqid(),
        }) as IAdditionalService,
    );
}

import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { LoyaltyFragmentFragmentDoc } from './loyaltyFragment';
export type CalculationsFragmentFragment = { __typename?: 'Calculations', loyalty: { __typename?: 'Loyalty', xPointsCurrencyCoef: number } };

export const CalculationsFragmentFragmentDoc = gql`
    fragment calculationsFragment on Calculations {
  loyalty {
    ...loyaltyFragment
  }
}
    ${LoyaltyFragmentFragmentDoc}`;
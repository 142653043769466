import { Money as MoneyType } from '__generated__/types';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'components/UI/Link';
import { dateFormat } from 'components/utils/formatters';
import React, { FC } from 'react';
import { routes } from 'routes';
import { Money } from 'utils/money';

import { ChangeRequestInvoiceFragment } from './graphql/__generated__/changeRequestInvoiceFragment';
import s from './PartnerChangeRequestModal.module.less';

const columns: ColumnsType<ChangeRequestInvoiceFragment> = [
  {
    title: 'Number',
    dataIndex: 'displayName',
    key: 'displayName',
    render: (value, { id, dealId }) => (
      <Link to={routes.editDealIncomingInvoicePath(dealId, id)} target="_blank">
        {value}
      </Link>
    ),
  },
  {
    title: 'Issued at',
    dataIndex: 'issuedAt',
    key: 'issuedAt',
    render: (issuedAt: string) => dateFormat(issuedAt),
    width: '130px',
  },
  {
    title: 'Balance',
    dataIndex: 'allocatedAmountMoney',
    key: 'allocatedAmountMoney',
    render: (allocatedAmountMoney: MoneyType) => <Money value={allocatedAmountMoney} />,
    width: '150px',
  },
];

export const InvoicesTable: FC<{ invoices: ChangeRequestInvoiceFragment[] }> = ({ invoices }) => {
  if (!invoices.length) {
    return 'No invoices';
  }

  return (
    <Table
      className={s.invoicesTable}
      dataSource={invoices}
      columns={columns}
      size="small"
      bordered
      pagination={false}
    />
  );
};

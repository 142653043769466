import { ProviderOrdersSamplesConfirmationStatusEnum } from '__generated__/types';
import { getRegionHeaderColor } from 'config/regions/utils';
import { ObjectType } from 'interfaces';

export const colors = {
  antdPrimary: '#1890FF',

  blueSky: '#F4FAFF', // blend
  blueLightest: '#55646D',
  blueLight: '#1392D1',
  blue: '#096DD9', // blue7
  blue1: '#EDF7FF',
  blue2: '#E0F1FD',
  blue2bg: '#ECF4FE',
  blue4: '#89CCFC',
  blue6: '#2690DD',
  blue7: '#096DD9',
  blue7bg: '#0E6AED',
  blue8: '#0B56C1',
  blueDark: '#245B86',

  geekBlue1: '#F0F5FF',

  ice1: '#EBFEFF',
  ice2: '#D8F7FE',

  dustBlue1: '#E7F1F5',
  dustBlue2: '#D8E7EC',
  dustBlue4: '#9BBCC7',
  dustBlue5: '#d2d9e1',
  dustBlue6: '#6A899B',
  dustBlue7: '#4C6985',

  brown1: '#F5F2F1',
  brown2: '#E9E2E1',
  brown4: '#C08D7D',
  brown6: '#865748',

  grayLight: '#FAFAFA',
  gray0: '#F9FBFC',
  gray1: '#FFFFFF',
  gray2: '#F3F5F6',
  gray3: '#F5F5F5',
  gray3Darker: '#E8EBED',
  gray4: '#BDBDBD',
  gray5: '#D9D9D9',
  gray6: '#BFBFBF',
  gray7: '#8C8C8C',
  gray8: '#595959',
  gray9: '#262626',
  grayX: '#678FA4',

  silver1: '#F0F2F5',

  vialet1: '#EFF0FF',
  vialet2: '#DCE8FF',
  vialet4: '#8691F9',
  vialet6: '#4D5BD8',

  orange3: '#D46B08',

  yellow: '#faad14',
  yellow1: '#FEFFE6',
  yellow7: '#D4B106',
  yellow8: '#ad8b00',

  redBgLighter: '#FBF0EF',
  redLightBg: '#ffabab',
  redLight: '#EB5757',
  red: '#EB5757',
  red2: '#C76767',
  red4: '#EEC2BF',
  red5: '#9E3C3C',
  red6: '#820014',
  red7: '#CF1322',
  red8: '#A1332B',
  red9: '#820014',
  red10: '#CB4239',
  redDarker: '#9E3C3C',

  greenLight: '#DEEDE9',

  greenMain: '#29A340',
  green1: '#EAF8F4',
  green2: '#D9E8E2',
  green4: '#80AC9D',
  green6: '#2F7C50',
  green9: '#135200',
  green: '#219653',

  sage1: '#F8F9EE',
  sage2: '#EDEED7',
  sage3: '#DEE4CE',
  sage4: '#DBDC98',
  sage6: '#A9AA77',
  sage7: '#829854',

  stoneDark: '#253843',
  stone: '#55646D',
  stoneLight: '#83949E',
  stoneLighter: '#CBD9DF',

  orange: '#F1830D',
  orangeLight: '#FFF0E3',

  greyLight: '#eceff2',
  grey: '#BDBDBD',
  greyDarker: '#BFBFBF',
  greyDark: '#A0A0A0',

  white: '#fff',

  lime9: '#3F6600',
  turquoise: '#E7FEFF',
  teal: '#4BA4A9',
};

export const objectC = {
  d: colors.dustBlue5,

  e: colors.green1,
  q: colors.green1,

  g: colors.blue1,
  r: colors.blue2,

  ii: colors.ice2,
  iic: colors.ice1,

  pz: colors.dustBlue2,
  prz: colors.dustBlue1,

  j: colors.brown1,

  qc: colors.sage1,

  oc: colors.green2,

  cl: colors.brown2,
  po: colors.brown2,

  pl: colors.vialet2,
  rf: colors.vialet1,

  hsc: colors.green2,

  sh: colors.sage2,
  dl: colors.sage2,
  su: colors.sage3,
  rfq: colors.turquoise,

  canceled: 'white',
};

export const objectBorder = {
  d: colors.dustBlue7,

  e: colors.green4,
  q: colors.green4,

  g: colors.blue4,
  r: colors.blue6,

  ii: colors.ice2,
  iic: colors.ice1,

  pz: colors.dustBlue6,
  prz: colors.dustBlue4,

  j: colors.brown4,

  qc: colors.sage4,

  oc: colors.green6,

  cl: colors.brown6,
  po: colors.brown6,

  hsc: colors.green4,

  pl: colors.vialet6,
  rf: colors.vialet4,

  su: colors.sage7,
  dl: colors.sage6,
  sh: colors.sage6,
  rfq: colors.teal,
};

export const sidebarBg: Partial<Record<ObjectType, string>> = {
  [ObjectType.D]: objectC.d,
  [ObjectType.Q]: objectC.e,
  [ObjectType.E]: objectC.e,

  [ObjectType.J]: objectC.j,
  [ObjectType.JO]: objectC.j,

  [ObjectType.QC]: objectC.qc,

  [ObjectType.OC]: objectC.oc,

  [ObjectType.CreditNote]: objectC.g,
  [ObjectType.OI]: objectC.r,
  [ObjectType.II]: objectC.ii,
  [ObjectType.PZ]: objectC.pz,

  [ObjectType.Payment]: objectC.pl,

  [ObjectType.PO]: objectC.po,

  [ObjectType.CL]: objectC.cl,
  [ObjectType.DL]: objectC.dl,
  [ObjectType.SH]: objectC.sh,
  [ObjectType.SU]: objectC.su,
  [ObjectType.RFQ]: objectC.rfq,
  [ObjectType.RfqOffer]: objectC.rfq,
};

export const sidebarItemBorder: Partial<Record<ObjectType, string>> = {
  [ObjectType.D]: objectBorder.d,
  [ObjectType.Q]: objectBorder.e,
  [ObjectType.E]: objectBorder.e,

  [ObjectType.J]: objectBorder.j,
  [ObjectType.JO]: objectBorder.j,

  [ObjectType.QC]: objectBorder.qc,

  [ObjectType.OC]: objectBorder.oc,

  [ObjectType.CreditNote]: objectBorder.g,
  [ObjectType.OI]: objectBorder.r,
  [ObjectType.II]: objectBorder.r,
  [ObjectType.PZ]: objectBorder.pz,

  [ObjectType.Payment]: objectBorder.pl,

  [ObjectType.PO]: objectBorder.po,

  [ObjectType.CL]: objectBorder.cl,
  [ObjectType.DL]: objectBorder.dl,
  [ObjectType.SH]: objectBorder.sh,
  [ObjectType.SU]: objectBorder.su,
  [ObjectType.RFQ]: objectBorder.rfq,
  [ObjectType.RfqOffer]: objectBorder.rfq,
};

export const sidebarItemBorderCancelled = '#d9d9d9';

const headerColor = getRegionHeaderColor();

export const bc = {
  info: colors.blueLight,
  error: colors.red,
  errorDark: colors.redDarker,
  success: colors.green,
  warning: colors.orange,
  formWarning: colors.yellow,
  deleted: colors.gray5,

  header: headerColor,

  border: colors.gray5,
  borderLight: '#E0E0E0',
  tableBorder: '#f0f0f0',
  formGroupDivider: colors.greyDarker,
  contentBackground: colors.greyLight,

  primary: colors.blue,
  primaryHover: colors.blue8,
  primaryButtonText: colors.white,

  link: colors.blue,
  hover: '#40a9ff',

  notes: colors.green2,

  tableHeader: '#f2f6f8',
  tableHeaderTextColor: '#8c94a0',

  disabled: colors.grey,

  line: colors.blueLightest,

  notice: colors.gray7,
  noticeSecondary: colors.gray4,

  collapse: '#F0F2F5',

  modal: {
    title: colors.gray8,
    header: '#F0F2F5',
  },

  sidebarHover: '#2690DD',

  formItemSec: colors.gray2,
};

export const providerOrderSamplesStatusColor: Record<ProviderOrdersSamplesConfirmationStatusEnum, string> = {
  [ProviderOrdersSamplesConfirmationStatusEnum.Required]: '#6A899B',
  [ProviderOrdersSamplesConfirmationStatusEnum.Confirmed]: '#29A340',
  [ProviderOrdersSamplesConfirmationStatusEnum.Rejected]: '#A1332B',
};

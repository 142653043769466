import { observer } from 'mobx-react-lite';
import React, { MouseEvent } from 'react';

import Content from './Content';
import { Modal } from './styled';

interface Props {
  visible: boolean;
  openModal: (e: MouseEvent<HTMLElement>) => void;
  closeModal: () => void;
  url: string;
  skipFetch?: boolean;
  onSkipCleaningComplete?: () => void;
  ignoreCleaned?: boolean;
}

const PreviewModal = observer<Props>((props) => {
  const { visible, openModal, closeModal, url, skipFetch, onSkipCleaningComplete, ignoreCleaned } = props;

  return (
    <Modal
      open={visible}
      title="Attached Files"
      centered
      onOk={openModal}
      onCancel={closeModal}
      footer={null}
      destroyOnClose
      isFullHeight
    >
      <Content
        url={url}
        skipFetch={skipFetch}
        onSkipCleaningComplete={onSkipCleaningComplete}
        ignoreCleaned={ignoreCleaned}
      />
    </Modal>
  );
});

export default PreviewModal;

import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { WorkspacePresetFragmentDoc } from '../../fragments/__generated__/workspacePreset';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DefaultWorkspacePresetQueryVariables = Types.Exact<{
  workspaceName: Types.Scalars['String']['input'];
}>;


export type DefaultWorkspacePresetQuery = { __typename?: 'Query', defaultWorkspacePreset: { __typename?: 'DefaultWorkspacePresets', globalDefaultPreset?: { __typename?: 'WorkspacePreset', id: number, name: string, workspaceName: string, public: boolean, query?: string | null, columns?: Array<string> | null, exportColumns?: Array<string> | null, createdAt: string, updatedAt: string, sortColumn?: string | null, sortDirection?: string | null, owner: { __typename?: 'User', id: number, name: string }, lastUpdatedBy?: { __typename?: 'User', id: number, name: string } | null } | null, personalDefaultPreset?: { __typename?: 'WorkspacePreset', id: number, name: string, workspaceName: string, public: boolean, query?: string | null, columns?: Array<string> | null, exportColumns?: Array<string> | null, createdAt: string, updatedAt: string, sortColumn?: string | null, sortDirection?: string | null, owner: { __typename?: 'User', id: number, name: string }, lastUpdatedBy?: { __typename?: 'User', id: number, name: string } | null } | null } };


export const DefaultWorkspacePresetDocument = gql`
    query defaultWorkspacePreset($workspaceName: String!) {
  defaultWorkspacePreset(workspaceName: $workspaceName) {
    globalDefaultPreset {
      ...workspacePreset
    }
    personalDefaultPreset {
      ...workspacePreset
    }
  }
}
    ${WorkspacePresetFragmentDoc}`;

/**
 * __useDefaultWorkspacePresetQuery__
 *
 * To run a query within a React component, call `useDefaultWorkspacePresetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultWorkspacePresetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultWorkspacePresetQuery({
 *   variables: {
 *      workspaceName: // value for 'workspaceName'
 *   },
 * });
 */
export function useDefaultWorkspacePresetQuery(baseOptions: Apollo.QueryHookOptions<DefaultWorkspacePresetQuery, DefaultWorkspacePresetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DefaultWorkspacePresetQuery, DefaultWorkspacePresetQueryVariables>(DefaultWorkspacePresetDocument, options);
      }
export function useDefaultWorkspacePresetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefaultWorkspacePresetQuery, DefaultWorkspacePresetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DefaultWorkspacePresetQuery, DefaultWorkspacePresetQueryVariables>(DefaultWorkspacePresetDocument, options);
        }
export function useDefaultWorkspacePresetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DefaultWorkspacePresetQuery, DefaultWorkspacePresetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DefaultWorkspacePresetQuery, DefaultWorkspacePresetQueryVariables>(DefaultWorkspacePresetDocument, options);
        }
export type DefaultWorkspacePresetQueryHookResult = ReturnType<typeof useDefaultWorkspacePresetQuery>;
export type DefaultWorkspacePresetLazyQueryHookResult = ReturnType<typeof useDefaultWorkspacePresetLazyQuery>;
export type DefaultWorkspacePresetSuspenseQueryHookResult = ReturnType<typeof useDefaultWorkspacePresetSuspenseQuery>;
export type DefaultWorkspacePresetQueryResult = Apollo.QueryResult<DefaultWorkspacePresetQuery, DefaultWorkspacePresetQueryVariables>;
import * as Types from '__generated__/types';

import { gql } from '@apollo/client';
import { ErpPermissionFragmentFragmentDoc } from '../../fragments/__generated__/erpPermissionFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ErpPermissionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ErpPermissionsQuery = { __typename?: 'Query', erpPermissions?: Array<{ __typename?: 'ErpPermissionsGrouped', group: string, erpPermissions: Array<{ __typename?: 'ErpPermission', id: number, group: string, action: string, name: string, label?: string | null }> }> | null };


export const ErpPermissionsDocument = gql`
    query erpPermissions {
  erpPermissions {
    group
    erpPermissions {
      ...erpPermissionFragment
    }
  }
}
    ${ErpPermissionFragmentFragmentDoc}`;

/**
 * __useErpPermissionsQuery__
 *
 * To run a query within a React component, call `useErpPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useErpPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useErpPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useErpPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<ErpPermissionsQuery, ErpPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ErpPermissionsQuery, ErpPermissionsQueryVariables>(ErpPermissionsDocument, options);
      }
export function useErpPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ErpPermissionsQuery, ErpPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ErpPermissionsQuery, ErpPermissionsQueryVariables>(ErpPermissionsDocument, options);
        }
export function useErpPermissionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ErpPermissionsQuery, ErpPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ErpPermissionsQuery, ErpPermissionsQueryVariables>(ErpPermissionsDocument, options);
        }
export type ErpPermissionsQueryHookResult = ReturnType<typeof useErpPermissionsQuery>;
export type ErpPermissionsLazyQueryHookResult = ReturnType<typeof useErpPermissionsLazyQuery>;
export type ErpPermissionsSuspenseQueryHookResult = ReturnType<typeof useErpPermissionsSuspenseQuery>;
export type ErpPermissionsQueryResult = Apollo.QueryResult<ErpPermissionsQuery, ErpPermissionsQueryVariables>;
import { ObjectType } from 'interfaces';
import { routes } from 'routes';

const documentUrl = (className: string, dealId: number | string, id?: number | string, parentId?: number | string) => {
  switch (className) {
    case ObjectType.OC:
      return routes.orderConfirmationPath(String(dealId));
    case ObjectType.E:
      return routes.enquiryEditPath(String(dealId), String(id));
    case ObjectType.Payment:
      return routes.editPaymentPath(String(dealId), String(id));
    case ObjectType.PO:
      return routes.editProviderOrderPath(String(dealId), String(id));
    case ObjectType.CL:
      return routes.editDealClaimPath(String(dealId), String(id));
    case ObjectType.DL:
      return routes.deliveryPath(String(dealId), String(id));
    case ObjectType.CreditNote:
      return routes.creditNotePath(String(dealId), String(id));
    case ObjectType.J:
      return routes.jobPath(String(dealId), String(id));
    case ObjectType.JO:
      return routes.jobOfferPath(String(dealId), String(parentId), String(id));
    case ObjectType.II:
      return routes.editDealIncomingInvoicePath(String(dealId), String(id));
    case ObjectType.OI:
      return routes.editPublishedOutcomingInvoicePath(String(dealId), String(id));
    case ObjectType.SH:
      return routes.editShippingPath(dealId, String(id));
    case ObjectType.MSH:
      return routes.editMasterShippingPath(String(id));
    case ObjectType.SU:
      return routes.editStoragePath(dealId, String(id));
    case ObjectType.QC:
      return routes.editDealQualityControlPath(dealId, String(id));
    case ObjectType.PR:
      return routes.providerPath(String(id));
    case ObjectType.D:
      return routes.dealPath(String(dealId));
    case ObjectType.Person:
      return routes.personPath(String(id));
    case ObjectType.BA:
      return routes.billingAccountPath(String(id));
    case ObjectType.SA:
      return routes.shippingAddressPath(String(id));
    case ObjectType.Organization:
      return routes.orgPath(String(id));
    case ObjectType.PZ:
      return routes.editPayoutPath(dealId, String(id));
    case ObjectType.Part:
      return `${routes.dealPartsPath(String(dealId))}?part_id=${String(id)}`;
    case ObjectType.EP:
      return `${routes.enquiryPath(dealId, String(id))}`;
    case ObjectType.RFQ:
      return routes.rfqEditPath(String(dealId), String(id));
    case ObjectType.RfqOffer:
      return routes.rfqOfferPath(String(dealId), String(parentId), String(id));
    default:
      return '#';
  }
};

export default documentUrl;
